import React from 'react'

const Overlay = ({ children }) => {
  return (
    <div className='Overlay'>
      <div className='Overlay-overlay'></div>
      {children}
    </div>
  );
}

export default Overlay;