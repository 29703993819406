import React from 'react'
import AppContext from '../context/AppContext';

const State = () => {
  const { state, setState } = React.useContext(AppContext);

  return (
    <div className='state'>
      <p>screen: {state.screen}</p>
    </div>
  );
}

export default State;