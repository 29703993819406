import React from 'react'
import AppContext from '../context/AppContext';
import useAudio from '../hooks/useAudio';

const Reloj = ({ timeOut, timeOutScreen, game }) => {
  const { state, setState } = React.useContext(AppContext);
  const audio = useAudio();

  let angle = 0;

  const update_time = () => {
    const segundero = document.querySelector('.Reloj-segundero')
    const seconds = document.querySelector('.Reloj-seconds')

    if (segundero && seconds) {
      segundero.style.transform = `rotate(${angle}deg)`;
      seconds.innerHTML = timeOut;

      angle += 360 / 60
      timeOut--

      const storagedTime = Number(localStorage.getItem('game'+game+'Time'));
      localStorage.setItem('game'+game+'Time', storagedTime + 1)
    } else {
      console.log('no existe segundero')
      clearInterval(countDown)
    }

    if (timeOut < 0) {
      console.log('timeout')
      audio.play('timeOver');
      clearInterval(countDown)

      setState({
        ...state,
        screen: timeOutScreen,
        clock: 'off',
      })
    }
  }

  const countDown = setInterval(update_time, 1000);
  
  return (
    <div className="Reloj slideRight">
      <div className='Reloj-texto'>¡Tienes <b className='Reloj-seconds'></b> segundos!</div>
      <div className='Reloj-base'>
        <div className='Reloj-segundero' />
      </div>
    </div>
  );
}

export default Reloj;