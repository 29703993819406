import React from 'react'
import { useNavigate } from 'react-router-dom'
import AppContext from '../context/AppContext';
import axios from "axios";
import State from '../components/State';
import Overlay from '../components/Overlay';
import useAudio from '../hooks/useAudio';

import logo from '../assets/images/common/logo-allegra.png';
import familia from '../assets/images/welcome/familia.png';
import cuadro from '../assets/images/finish/cuadro-experiencia.png';
import matera from '../assets/images/welcome/matera.png';

import gracias from '../assets/images/finish/texto-gracias.png'
import enviar from '../assets/images/finish/boton-enviar.png'
import legal from '../assets/images/finish/texto-legal.png';
import volver from '../assets/images/finish/boton-menu.png';

const Finish = () => {
  const { state, setState } = React.useContext(AppContext);
  const navigate = useNavigate();
  const audio = useAudio();

  console.log('finish');

  // PAIS
  const country = 'Colombia';

  /**
   * HANDLE STATE
   * Establece el nivel en 1
   * 
   * @param {string} screen   Guarda la pantalla actual
   * @param {string} clock    Dice si el reloj esta o no activo
   */

   const handleState = (screen) => {
    setState({
      ...state,
      level: '',
      screen: screen,
    })
  }
  
  /**
   * HANDLE NAVIGATE
   * Cierra el modal
   * Abre la ruta solicitada
   * @param {string} url    Ruta a la cual se dirige
   */

  const handleNavigate = (url) => {
    setState({
      ...state,
      screen: false,
    })
    navigate('/'+url, { replace: true });
  }

  /**
   * HANDLE OPINION
   * Muestra el popup de mensaje
   * Captura los datos de usuario y la calificacion
   * @param {number} qualification  Calificacion
   */
  let formData = new FormData();

  const handleOpinion = (qualification) => {
    const finishMessage = document.querySelector('.Finish-message');
    finishMessage.classList.add('show');

    formData.append('country', country);
    formData.append('pharmacy', localStorage.getItem('pharmacy'));
    formData.append('fullname', localStorage.getItem('fullname'));
    formData.append('qualification', qualification);
  }

  /**
   * HANDLE MESSAGE
   * Captura el mensaje
   * Guarda los datos en el servidor
   */
  const handleMessage = () => {
    const message = document.querySelector('.Finish-comentarios');
    formData.append('message', message.value);

    axios.post('http://api-allegra-games.eml.com.co/api/v1/opinion/store', formData, {
      headers:{
        "Authorization": `Bearer ${ JSON.parse(localStorage.getItem('token'))}`
      }
    })
    .then((res) => {
      handleState('cierre');
    })
    .catch((err) => {
      console.log('error al guardar la opinion:', err);
      handleState('cierre');
    })
  }

  return (
    <div className='Finish'>
      <State />
      <div className='Finish-background' />
      <img className='Finish-logo-allegra' src={logo} alt="Logo Allegra" />
      
      {!state.screen &&
        <React.Fragment>
          <img className='Finish-familia slideRight' src={familia} alt="Familia" />
          <img className='Finish-matera' src={matera} alt="Matera" />

          <div className='Finish-calificacion'>
            <img className='Finish-cuadro zoomIn' src={cuadro} alt="Cuadro" />
            <div className='Finish-buena' onClick={() => {
              handleOpinion(1);
              audio.play('button');
            }} />
            <div className='Finish-regular' onClick={() => {
              handleOpinion(2);
              audio.play('button');
            }} />
            <div className='Finish-mala' onClick={() => {
              handleOpinion(3);
              audio.play('button');
            }} />
          </div>
          
          <div className="Finish-message">
            <Overlay>
              <div className="Finish-adios">
                <img className='Finish-gracias' src={gracias} alt="Gracias" />
                <textarea name='comentarios' className='Finish-comentarios' rows="10"></textarea>
                <img className='Finish-enviar' src={enviar} alt="Enviar" onClick={() => {
                  handleMessage();
                  audio.play('button');
                }} />
              </div>
            </Overlay>
          </div>
        </React.Fragment>
      }

      {state.screen === 'cierre' && 
        <Overlay>
          <div className='Finish-cierre' onClick={() => {
            handleNavigate('welcome');
            audio.play('button');
          }}>
            <img className='Finish-logo' src={logo} alt="Logo Allegra" />
            <img className='Finish-legal' src={legal} alt="Legal" />
            <img className='Finish-volver' src={volver} alt="Volver" />
          </div>
        </Overlay>
      }
    </div>
  );
}

export default Finish;