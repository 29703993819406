import React from 'react'
import { useNavigate } from 'react-router-dom'
import AppContext from '../context/AppContext';
import gsap from 'gsap';
import Draggable from 'gsap/Draggable';
import State from '../components/State';
import Overlay from '../components/Overlay';
import Reloj from '../components/Reloj';
import useAudio from '../hooks/useAudio';

import logoAllegra from '../assets/images/common/logo-allegra.png';
import jugar from '../assets/images/home/boton-jugar.png';
import nivel from '../assets/images/level-4/nivel4.png';
import ninaPerro from '../assets/images/level-4/nina-perro.png';
import titulo from '../assets/images/level-4/cuadro-nivel4.png';
import producto from '../assets/images/level-4/cale-allegra-30.png';

import ninaTristePerro from '../assets/images/level-4/nina-triste-perro.png';
import mensaje from '../assets/images/level-4/cuadro-achis.png';

import banoPregunta from '../assets/images/level-4/texto-reto1-pregunta.png';
import banoPerritos from '../assets/images/level-4/fondo-nivel4.jpg';
import banoBanera from '../assets/images/level-4/banera.png';
import banoPerrito1 from '../assets/images/level-4/perrito1.png';
import banoPerrito2 from '../assets/images/level-4/perrito2.png';
import banoPerrito3 from '../assets/images/level-4/perrito3.png';
import banoPerrito4 from '../assets/images/level-4/perrito4.png';
import manoJabon from '../assets/images/level-4/reto1-mano-jabon.png';
import espuma from '../assets/images/level-4/reto1-espuma.png';
import banoSegundos from '../assets/images/level-4/texto-reto1-segundos.png';

import ninaFelizMora from '../assets/images/level-4/nina-feliz-mora.png';
import puntosExtra from '../assets/images/level-4/texto-puntos-extra.png';

import mora from '../assets/images/level-4/reto1-mora.png';
import extrasPregunta from '../assets/images/level-4/texto2-reto1-pregunta.png';
import caja from '../assets/images/level-4/cale-allegra-30.png';

import genial from '../assets/images/level-4/cuadro-genial.png';

import letrasPregunta from '../assets/images/level-4/texto-reto2-pregunta.png';
import letrasSegundos from '../assets/images/level-4/texto-reto2-segundos.png';
import listo from '../assets/images/level-4/listo.png';

import ninaFeliz from '../assets/images/level-4/nina-feliz.png';
import ninaTriste from '../assets/images/level-4/nina-triste.png';
import letrasLograste from '../assets/images/level-4/cuadro-lograste.png';
import letrasFallaste from '../assets/images/level-4/texto-fallaste.png';

import referencias from '../assets/images/level-4/texto-ref.png';
import cerrar from '../assets/images/common/boton-cerrar.png';

import iconoMusicaOn from '../assets/images/common/icono-musica-on.png';
import iconoMusicaOff from '../assets/images/common/icono-musica-off.png';

const Level4 = () => {
  const { state, setState } = React.useContext(AppContext);
  const navigate = useNavigate();
  const audio = useAudio();
  gsap.registerPlugin(Draggable);

  console.log('level4');
  
  /**
   * HANDLE STATE
   * 
   * @param {string} screen   Guarda la pantalla actual
   * @param {string} clock    Dice si el reloj esta o no activo
   */

  const handleState = (screen) => {
    setState({
      ...state,
      screen: screen,
      // clock: clock,
    })
  }

  /**
   * HANDLE NAVIGATE
   * Cierra el modal
   * Abre la ruta solicitada
   * @param {string} url    Ruta a la cual se dirige
   */

   const handleNavigate = (url) => {
    setState({
      ...state,
      screen: false,
    })
    navigate('/'+url, { replace: true });
  }

  // BANO PERRITOS

  if (state.screen === 'bano') {
    let limpieza = 0;
    let score = 0;
    let perrito = 1;
    
    setTimeout(() => {
      const espuma = document.querySelector('.Level4-bano-espuma');
      const number = document.querySelector('.Level4-bano-score-number');

      Draggable.create("#mano-jabon", {
        onDrag: () => {
          limpieza++
          console.log(limpieza);

          if (limpieza > 150) {
            limpieza = 0;

            espuma.classList.add('fadeInOut');
            number.classList.add('zoomIn');

            score++;

            number.innerHTML = score;
            localStorage.setItem('gameBanoScore', score);

            setTimeout(() => {
              const perritoActual = document.querySelector('.Level4-bano-perrito'+perrito);
              const perritoNuevo = document.querySelector('.Level4-bano-perrito'+(perrito+1));

              if (perritoActual) {
                perritoActual.classList.add('fadeOut');
              }

              if (perritoNuevo) {
                perritoNuevo.classList.add('fadeIn');
              }

              perrito++;
            }, 800);

            setTimeout(() => {
              espuma.classList.remove('fadeInOut');
              number.classList.remove('zoomIn');

              console.log('score:', score);

              if (perrito > 4) {
                console.log('ganaste');
                handleState('bano-ok');
              }
            }, 1600);
          }
        }
      });
    }, 500);
  }

  // EXTRAS 

  if (state.screen === 'extras') {
    let limpieza = 0;
    let score = Number(localStorage.getItem('gameBanoScore'));
    let perrito = 1;
    
    setTimeout(() => {
      const espuma = document.querySelector('.Level4-extras-espuma');
      const number = document.querySelector('.Level4-extras-score-number');

      const triste = document.querySelector('.Level4-extras-nina-triste');
      triste.classList.add('visible');

      number.innerHTML = Number(localStorage.getItem('gameBanoScore'));

      Draggable.create("#mano-jabon", {
        onDrag: () => {
          limpieza++
          console.log(limpieza);

          if (limpieza > 150) {
            limpieza = 0;

            espuma.classList.add('fadeInOut');
            number.classList.add('zoomIn');

            score++;

            number.innerHTML = score;
            localStorage.setItem('gameBanoScore', Number(localStorage.getItem('gameBanoScore')) + 1);

            setTimeout(() => {
              const perritoActual = document.querySelector('.Level4-extras-perrito'+perrito);
              const perritoNuevo = document.querySelector('.Level4-extras-perrito'+(perrito+1));

              if (perritoActual) {
                perritoActual.classList.add('fadeOut');
              }

              if (perritoNuevo) {
                perritoNuevo.classList.add('fadeIn');
              }

              perrito++;
            }, 800);

            setTimeout(() => {
              espuma.classList.remove('fadeInOut');
              number.classList.remove('zoomIn');

              console.log('score:', score);

              if (perrito > 4) {
                console.log('ganaste');
                handleState('extras-ok');
              }
            }, 1600);
          }
        }
      });
    }, 500);
  }

  /**
   * HANDLE MORAS
   * Gana puntos al presionar la caja
   */

  const handleMoras = () => {
    const caja = document.querySelector('.Level4-extras-caja');
    const instruccion = document.querySelector('.Level4-extras-instruccion');
    const mora = document.querySelector('.Level4-extras-mora');

    const feliz = document.querySelector('.Level4-extras-nina-feliz');
    const triste = document.querySelector('.Level4-extras-nina-triste');

    triste.classList.remove('visible');
    feliz.classList.add('visible');

    caja.classList.add('inactive');
    instruccion.classList.add('inactive');
    mora.classList.add('pop');

    const moras = document.querySelector('.Level4-extras-moras');
    moras.innerHTML = Number(moras.innerHTML) + 1

    let score = Number(localStorage.getItem('gameBanoScore'));
    localStorage.setItem('gameBanoScore', score + 1);

    setTimeout(() => {
      caja.classList.remove('inactive');
      instruccion.classList.remove('inactive');
      mora.classList.remove('pop');

      triste.classList.add('visible');
      feliz.classList.remove('visible');
    }, 500);
  }

  /**
   * HANDLE LETTERS
   * Valida si todas las letras ingresadas con correctas
   */

   const handleLetters = () => {
    const lettersInputs = document.querySelectorAll('.Level4-letras-inputs input');
    
    lettersInputs.forEach(input => {
      console.log(input.value.toLowerCase());
      console.log(input.dataset.word.toLowerCase());
      
      if (input.value.toLowerCase() === input.dataset.word.toLowerCase()) {
        input.classList.add('word-ok');
        input.setAttribute('readonly', 'readonly');
      } else {
        input.classList.remove('word-ok');
        input.classList.add('word-error');
      }
    });
    
    const lettersInputsOks = document.querySelectorAll('.Level4-letras-inputs input.word-ok');

    if (lettersInputs.length === lettersInputsOks.length) {
      console.log('ganaste');

      const letters = document.querySelector('.Level4-letras-inputs');
      letters.classList.add('zoomIn');
      
      setTimeout(() => {
        handleState('letras-ok');
      }, 2000);
    }
  }

  /**
   * HANDLE MUSIC
   * Inicia la musica si no esta sonando
   */

   const handleMusic = () => {
    console.log('evaluando musica');
    const music = document.querySelector('.audio-music')

    if (music.paused) {
      console.log('musica esta quieta');
      audio.restart('music');
    } else {
      console.log('musica esta sonando');
    }
  }

  /**
   * SWITCH MUSIC
   * Enciende o apaga la musica
   */

  const switchMusic = (state) => {
    const iconoMusica = document.querySelector('.Home-icono-musica');

    if (iconoMusica) {
      if (state === 'on') {
        audio.volumeOn('music');
        iconoMusica.src = iconoMusicaOn;
        localStorage.setItem('musicAlegra', 'on');
      } else if (state === 'off') {
        audio.volumeOff('music');
        iconoMusica.src = iconoMusicaOff;
        localStorage.setItem('musicAlegra', 'off');
      } else if (state === 'toggle') {
        if (audio.isPlay('music')) {
          if (localStorage.getItem('musicAlegra') === 'on') {
            switchMusic('off');
          } else if (localStorage.getItem('musicAlegra') === 'off') {
            switchMusic('on');
          }
        } else {
          audio.restart('music');
          switchMusic('on');
        }
      }
    }
  }

  setTimeout(() => {
    if (audio.isPlay('music')) {
      if (localStorage.getItem('musicAlegra') === 'on') {
        switchMusic('on');
      } else if (localStorage.getItem('musicAlegra') === 'off') {
        switchMusic('off');
      }
    } else {
      switchMusic('off');
    }    
  }, 500);

  /**
   * SAVE SCORE
   * Guarda el puntaje en el servidor
   */

  const saveScore = () => {
    const score = {
      name: localStorage.getItem('fullname'),
      pharmacy: localStorage.getItem('pharmacy'),
      score: Number(localStorage.getItem('gameBanoScore')) + Number(localStorage.getItem('gameLetrasScore')),
      time: Number(localStorage.getItem('gameBanoTime')) + Number(localStorage.getItem('gameLetrasTime')),
    }

    if (localStorage.getItem('gameBano') === 'finish' && localStorage.getItem('gameLetras') === 'finish') {
      console.log('Save score!');
      
      const rankingAllegraLevel4 = localStorage.getItem('rankingAllegraLevel4');
  
      if (!rankingAllegraLevel4) {
        console.log('no existe ranking');
        localStorage.setItem('rankingAllegraLevel4', JSON.stringify([score]));
      } else {
        console.log('ya existe ranking');
        const ranking = JSON.parse(localStorage.getItem('rankingAllegraLevel4'));
        ranking.push(score);
  
        localStorage.setItem('rankingAllegraLevel4', JSON.stringify(ranking));
      }

      localStorage.setItem('gameBano', 'saved');
      localStorage.setItem('gameLetras', 'saved');
    }
  }

  /**
   * USE EFFECT
   * Acciones iniciales
   */

  // React.useEffect(() => {
  //   handleState('seccion4-ok');
  // }, []);

  return (
    <div className='Level4'>
      <State />
      <div className='Level4-background fadeIn' />
      <img className='Level4-logo-allegra' src={logoAllegra} alt="Logo Alegra" />
      <img className='Level4-nivel' src={nivel} alt="Nivel" />
      <button className='button right bottom' onClick={() => { 
        handleNavigate('welcome'); 
        audio.play('modalOut') 
      }} >MENU</button>
      <img className='Home-icono-musica absolute left bottom' src={iconoMusicaOn} alt="Musica" onClick={() => switchMusic('toggle')} />

      {/* Presentacion */}

      {!state.screen &&
        <React.Fragment>
          { localStorage.setItem('gameBano', 'ready') }
          { localStorage.setItem('gameBanoScore', 0) }
          { localStorage.setItem('gameBanoTime', 0) }
          { localStorage.setItem('gameLetras', 'ready') }
          { localStorage.setItem('gameLetrasScore', 0) }
          { localStorage.setItem('gameLetrasTime', 0) }

          <img className='Level4-nina-perro slideUp' src={ninaPerro} alt="Nina Perro" />
          <img className='Level4-producto zoomIn' src={producto} alt="Producto" />
          <img className='Level4-titulo' src={titulo} alt="Titulo" />
          <img className='Home-jugar-btn' src={jugar} alt="Jugar" onClick={() => { 
            handleState('instrucciones');
            handleMusic();
            audio.play('button');
          }} />
        </React.Fragment>
      }

      {/* Instrucciones */}

      {state.screen === 'instrucciones' &&
        <React.Fragment>
          <img className='Level4-nina-triste-perro' src={ninaTristePerro} alt="Mujer" />
          <img className='Level4-mensaje zoomIn' src={mensaje} alt="Titulo" onClick={() => { 
            handleState('bano');
            audio.play('button');
          }} />
        </React.Fragment>
      }

      {/* Bano */}

      {state.screen === 'bano' &&
        <Overlay>
          <div className="Level4-bano-wrapper">
            <img className='Level4-bano-pregunta' src={banoPregunta} alt="Pregunta" />
            <div className="Level4-bano-juego">
              <div className='Level4-bano-score'>
                <p>PERRITOS<br/> LIMPIOS<br/>
                  <span className='Level4-bano-score-number'>0</span>
                </p>
              </div>
              <div className='Level4-bano-perritos-wrapper'>
                <img className='Level4-bano-perritos' src={banoPerritos} alt="Bano Perritos" />
                <img className='Level4-bano-perrito1' src={banoPerrito1} alt="Perrito" />
                <img className='Level4-bano-perrito2' src={banoPerrito2} alt="Perrito" />
                <img className='Level4-bano-perrito3' src={banoPerrito3} alt="Perrito" />
                <img className='Level4-bano-perrito4' src={banoPerrito4} alt="Perrito" />
                <img className='Level4-bano-banera' src={banoBanera} alt="Banera" />
                <img className='Level4-bano-espuma' src={espuma} alt="Espuma" />
              </div>
              <img className='Level4-bano-mano-jabon' id='mano-jabon' src={manoJabon} alt="Mano" />
            </div>
          </div>
          <button className='button right bottom z-200' onClick={() => { 
            handleState(''); 
            audio.play('modalOut') 
          }} >SALIR</button>
          
          <img className='Level4-bano-segundos' src={banoSegundos} alt="Segundos Reto" />
          <Reloj timeOut="20" timeOutScreen={'bano-ok'} game="Bano" />
        </Overlay>
      }

      {/* Bano - Callbacks */}

      {state.screen === 'bano-ok' &&
        <Overlay>
          <img className='Level4-bano-nina-feliz-mora slideUp' src={ninaFelizMora} alt="Nina Feliz" />
          <img className='Level4-bano-puntos-extras zoomIn' src={puntosExtra} alt="Puntos Extra" onClick={() => {
            handleState('extras');
            audio.play('button');
          }} />
        </Overlay>
      }

      {/* Extras */}

      {state.screen === 'extras' &&
        <Overlay>
          <div className="Level4-extras-wrapper">
            <img className='Level4-extras-pregunta' src={extrasPregunta} alt="Pregunta" />
            <div className="Level4-extras-juego">
              <div className='Level4-extras-score'>
                <p>x<span className='Level4-extras-moras'>0</span></p>
                <img className='Level4-extras-mora' src={mora} alt="Mora" />
                <p>PERRITOS<br/> LIMPIOS<br/>
                  <span className='Level4-extras-score-number'>0</span>
                </p>
              </div>
              <div className='Level4-extras-perritos-wrapper'>
                <img className='Level4-extras-perritos' src={banoPerritos} alt="Bano Perritos" />
                <img className='Level4-extras-perrito1' src={banoPerrito1} alt="Perrito" />
                <img className='Level4-extras-perrito2' src={banoPerrito2} alt="Perrito" />
                <img className='Level4-extras-perrito3' src={banoPerrito3} alt="Perrito" />
                <img className='Level4-extras-perrito4' src={banoPerrito4} alt="Perrito" />
                <img className='Level4-extras-banera' src={banoBanera} alt="Banera" />
                <img className='Level4-extras-espuma' src={espuma} alt="Espuma" />
              </div>
              <img className='Level4-extras-mano-jabon' id='mano-jabon' src={manoJabon} alt="Mano" />
              <div className='Level4-extras-cajas-wrapper'>
                <p className='Level4-extras-instruccion'>¡Toca la caja!</p>
                <img className='Level4-extras-caja yoyo' src={caja} alt="Caja" onClick={() => handleMoras()} />
                <img className='Level4-extras-nina-triste' src={ninaTriste} alt="Nina Triste" />
                <img className='Level4-extras-nina-feliz' src={ninaFeliz} alt="Nina Feliz" />
              </div>
            </div>
          </div>
          <button className='button right bottom z-200' onClick={() => { 
            handleState(''); 
            audio.play('modalOut') 
          }} >SALIR</button>
          
          <img className='Level4-extras-segundos' src={banoSegundos} alt="Segundos Reto" />
          <Reloj timeOut="20" timeOutScreen={'extras-ok'} game="Bano" />
        </Overlay>
      }

      {/* Bano - Callbacks */}

      {state.screen === 'extras-ok' &&
        <Overlay>
          { localStorage.getItem('gameBano') === 'ready' && localStorage.setItem('gameBano', 'finish') }

          <img className='Level4-extras-nina-feliz-mora slideUp' src={ninaFelizMora} alt="Nina Feliz" />
          <img className='Level4-extras-genial zoomIn' src={genial} alt="Genial" onClick={() => {
            handleState('letras');
            audio.play('button');
          }} />
        </Overlay>
      }

      {/* Letras */}

      {state.screen === 'letras' &&
        <Overlay>
          <div className="Level4-letras-wrapper">
            <img className='Level4-letras-pregunta' src={letrasPregunta} alt="Pregunta" />
            <div className="Level4-letras-juego">
              <div className='Level4-letras-inputs'>
                <div className="Level4-letras-row">
                  <span>R</span>
                  <input type="text" data-word="I" />
                  <span>N</span>
                  <span>I</span>
                  <input type="text" data-word="T" />
                  <input type="text" data-word="I" />
                  <span>S</span>
                  <span className='slash'>/</span>
                  <input type="text" data-word="A" />
                  <span>L</span>
                  <span>E</span>
                  <span>R</span>
                  <input type="text" data-word="G" />
                  <span>I</span>
                  <span>C</span>
                  <span>A</span>
                </div>
                <div className="Level4-letras-row">
                  <span>F</span>
                  <input type="text" data-word="L" />
                  <span>U</span>
                  <input type="text" data-word="J" />
                  <span>O</span>
                  <span className='slash'>/</span>
                  <input type="text" data-word="N" />
                  <span>A</span>
                  <span>S</span>
                  <input type="text" data-word="A" />
                  <span>L</span>
                </div>
                <div className="Level4-letras-row">
                  <span>O</span>
                  <span>J</span>
                  <input type="text" data-word="O" />
                  <span>S</span>
                  <span className='slash'>/</span>
                  <input type="text" data-word="L" />
                  <span>L</span>
                  <span>O</span>
                  <span>R</span>
                  <input type="text" data-word="O" />
                  <span>S</span>
                  <span>O</span>
                  <input type="text" data-word="S" />
                </div>
                <div className="Level4-letras-row">
                  <span>P</span>
                  <span>I</span>
                  <input type="text" data-word="C" />
                  <input type="text" data-word="A" />
                  <span>Z</span>
                  <span>Ó</span>
                  <input type="text" data-word="N" />
                  <span className='slash'>/</span>
                  <span>E</span>
                  <span>N</span>
                  <span className='slash'>/</span>
                  <span>N</span>
                  <input type="text" data-word="A" />
                  <span>R</span>
                  <input type="text" data-word="I" />
                  <span>Z</span>
                </div>
                <div className="Level4-letras-row">
                  <input type="text" data-word="P" />
                  <span>I</span>
                  <span>C</span>
                  <span>A</span>
                  <input type="text" data-word="Z" />
                  <span>Ó</span>
                  <input type="text" data-word="N" />
                  <span className='slash'>/</span>
                  <span>E</span>
                  <span>N</span>
                  <span className='slash'>/</span>
                  <span>G</span>
                  <input type="text" data-word="A" />
                  <span>R</span>
                  <input type="text" data-word="G" />
                  <input type="text" data-word="A" />
                  <span>N</span>
                  <span>T</span>
                  <input type="text" data-word="A" />
                </div>
                <div className="Level4-letras-row">
                  <span>U</span>
                  <input type="text" data-word="R" />
                  <span>T</span>
                  <span>I</span>
                  <input type="text" data-word="C" />
                  <input type="text" data-word="A" />
                  <span>R</span>
                  <input type="text" data-word="I" />
                  <span>A</span>
                  <span className='slash'>/</span>
                  <span>C</span>
                  <span>R</span>
                  <input type="text" data-word="O" />
                  <span>N</span>
                  <span>I</span>
                  <input type="text" data-word="C" />
                  <span>A</span>
                  <span className='slash'>/</span>
                  <span>I</span>
                  <span>D</span>
                  <input type="text" data-word="I" />
                  <input type="text" data-word="O" />
                  <span>P</span>
                  <span>Á</span>
                  <input type="text" data-word="T" />
                  <span>I</span>
                  <input type="text" data-word="C" />
                  <input type="text" data-word="A" />
                </div>
                <div className="Level4-letras-row">
                  <span>S</span>
                  <input type="text" data-word="A" />
                  <span>B</span>
                  <span>O</span>
                  <input type="text" data-word="R" />
                  <span className='slash'>/</span>
                  <span>A</span>
                  <span className='slash'>/</span>
                  <input type="text" data-word="F" />
                  <span>R</span>
                  <span>A</span>
                  <span>M</span>
                  <input type="text" data-word="B" />
                  <input type="text" data-word="U" />
                  <span>E</span>
                  <input type="text" data-word="S" />
                  <span>A</span>
                </div>
                <div className="Level4-letras-row">
                  <span>D</span>
                  <input type="text" data-word="O" />
                  <input type="text" data-word="S" />
                  <span>I</span>
                  <span>F</span>
                  <input type="text" data-word="I" />
                  <span>C</span>
                  <input type="text" data-word="A" />
                  <input type="text" data-word="C" />
                  <span>I</span>
                  <span>Ó</span>
                  <input type="text" data-word="N" />
                  <span className='slash'>/</span>
                  <input type="text" data-word="F" />
                  <span>Á</span>
                  <span>C</span>
                  <input type="text" data-word="I" />
                  <span>L</span>
                </div>
                <div className="Level4-letras-row">
                <span></span>
                  <span>A</span>
                  <input type="text" data-word="L" />
                  <input type="text" data-word="L" />
                  <span>E</span>
                  <span>G</span>
                  <span>R</span>
                  <input type="text" data-word="A" />
                  <span className='slash'>/</span>
                  <span>P</span>
                  <span>E</span>
                  <input type="text" data-word="D" />
                  <input type="text" data-word="I" />
                  <span>Á</span>
                  <input type="text" data-word="T" />
                  <span>R</span>
                  <span>I</span>
                  <span>C</span>
                  <span>O</span>
                </div>
              </div>
              <div className='Level4-letras-listo'>
                <img className='Level4-letras-listo-btn' src={listo} alt="Listo" onClick={() => handleLetters()} />
              </div>
            </div>
          </div>
          <button className='button right bottom z-200' onClick={() => { 
            handleState(''); 
            audio.play('modalOut')
          }} >SALIR</button>
          
          <img className='Level4-letras-segundos' src={letrasSegundos} alt="Segundos Reto" />
          <Reloj timeOut="108" timeOutScreen={'letras-timeout'} game="Letras" />
        </Overlay>
      }

      {/* Letras - Callbacks */}

      {state.screen === 'letras-timeout' &&
        <Overlay>
          <img className='Level4-letras-nina-triste slideRight' src={ninaTriste} alt="Nina" />
          <img className='Level4-letras-ups-tiempo' src={letrasFallaste} alt="Timeout" onClick={() => {
            handleState('letras');
            audio.play('modalOut');
          }} />
        </Overlay>
      }

      {state.screen === 'letras-ok' &&
        <Overlay>
          { localStorage.getItem('gameLetras') === 'ready' && localStorage.setItem('gameLetras', 'finish') }
          { localStorage.setItem('gameLetrasScore', 9) }

          { saveScore() }

          <img className='Level4-letras-nina-feliz slideUp' src={ninaFeliz} alt="Nina" />
          <div className="Level4-letras-lograste-wrapper zoomIn">
            <img className='Level4-letras-lograste' src={letrasLograste} alt="Lograste" />
            <div className='Level4-letras-continuar' onClick={() => {
              handleNavigate('finish');
              audio.play('button');
            }} />
            <div className='Level4-letras-menu' onClick={() => {
              handleNavigate('welcome');
              audio.play('button');
            }} />
            <div className='Level4-letras-referencias' onClick={() => {
              handleState('referencias');
              audio.play('button');
            }} />
          </div>
        </Overlay>
      }

      {/* Referencias */}

      {state.screen === 'referencias' &&
        <Overlay>
          <div>
            <img className='Level4-referencias-close' src={cerrar} alt="Cerrar" onClick={() => {
              handleState('letras-ok');
              audio.play('modalOut');
            }} />
            <img className='Level4-referencias' src={referencias} alt='Referencias' />
          </div>
        </Overlay>
      }
    </div>
  );
}

export default Level4;