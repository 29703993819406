import React from 'react'

import image1 from '../assets/images/common/boton-cerrar.png';
import image2 from '../assets/images/common/circulo-blanco.png';
import image3 from '../assets/images/common/circulo-naranja.png';
import image4 from '../assets/images/common/logo-allegra.png';

import image5 from '../assets/images/finish/boton-enviar.png';
import image6 from '../assets/images/finish/boton-menu.png';
import image7 from '../assets/images/finish/cuadro-experiencia.png';
import image8 from '../assets/images/finish/logo-allegra-cierre.png';
import image9 from '../assets/images/finish/texto-gracias.png';
import image10 from '../assets/images/finish/texto-legal.png';

import image11 from '../assets/images/home/fondo-inicio.jpg';
import image12 from '../assets/images/home/boton-jugar.png';
import image13 from '../assets/images/home/cuadro-registro.png';
import image14 from '../assets/images/home/mujer.png';
import image15 from '../assets/images/home/titulo.png';

import image16 from '../assets/images/level-1/fondo-nivel1.jpg';
import image17 from '../assets/images/level-1/caja-allegra-120.png';
import image18 from '../assets/images/level-1/cuadro-genial.png';
import image19 from '../assets/images/level-1/cuadro-lograste.png';
import image20 from '../assets/images/level-1/cuadro-muy-bien.png';
import image21 from '../assets/images/level-1/cuadro-nivel1.png';
import image22 from '../assets/images/level-1/cuadro-organicemos.png';
import image23 from '../assets/images/level-1/mujer-brava.png';
import image24 from '../assets/images/level-1/mujer-cuadro.png';
import image25 from '../assets/images/level-1/mujer-feliz.png';
import image26 from '../assets/images/level-1/nivel1.png';
import image27 from '../assets/images/level-1/objetos.png';
import image28 from '../assets/images/level-1/reto1-boton-A.png';
import image29 from '../assets/images/level-1/reto1-boton-B.png';
import image30 from '../assets/images/level-1/reto1-boton-C.png';
import image31 from '../assets/images/level-1/reto2-palabras.png';
import image32 from '../assets/images/level-1/reto2-sopa-letras.png';
import image33 from '../assets/images/level-1/reto3-caja.png';
import image34 from '../assets/images/level-1/reto3-foto.png';
import image35 from '../assets/images/level-1/reto3-mas.png';
import image36 from '../assets/images/level-1/reto3-solucion.png';
import image37 from '../assets/images/level-1/texto-fallaste.png';
import image38 from '../assets/images/level-1/texto-ref.png';
import image39 from '../assets/images/level-1/texto-resp-incorrecta.png';
import image40 from '../assets/images/level-1/texto-reto1-pregunta.png';
import image41 from '../assets/images/level-1/texto-reto1-segundos.png';
import image42 from '../assets/images/level-1/texto-reto2-pregunta.png';
import image43 from '../assets/images/level-1/texto-reto2-segundos.png';
import image44 from '../assets/images/level-1/texto-reto3-pregunta.png';
import image45 from '../assets/images/level-1/texto-reto3-segundos.png';
import image46 from '../assets/images/level-1/texto-ups.png';

import image47 from '../assets/images/level-2/fondo-nivel2.jpg';
import image48 from '../assets/images/level-2/caja-allegra-180.png';
import image49 from '../assets/images/level-2/circulo-azul.png';
import image50 from '../assets/images/level-2/cuadro-bien-hecho.png';
import image51 from '../assets/images/level-2/cuadro-excelente.png';
import image52 from '../assets/images/level-2/cuadro-nivel2.png';
import image53 from '../assets/images/level-2/cuadro-pica.png';
import image54 from '../assets/images/level-2/hombre-enfermo.png';
import image55 from '../assets/images/level-2/hombre-feliz.png';
import image56 from '../assets/images/level-2/nivel2.png';
import image57 from '../assets/images/level-2/reto1-cuadro1.png';
import image58 from '../assets/images/level-2/reto1-cuadro2.png';
import image59 from '../assets/images/level-2/reto1-cuadro3.png';
import image60 from '../assets/images/level-2/reto1-cuadro4.png';
import image61 from '../assets/images/level-2/reto1-cuadro5.png';
import image62 from '../assets/images/level-2/reto1-cuadro6.png';
import image63 from '../assets/images/level-2/reto1-texto-legal.png';
import image64 from '../assets/images/level-2/reto2-laberinto.png';
import image65 from '../assets/images/level-2/reto2-mas.png';
import image66 from '../assets/images/level-2/reto2-solucion.png';
import image67 from '../assets/images/level-2/texto-fallaste.png';
import image68 from '../assets/images/level-2/texto-ref.png';
import image69 from '../assets/images/level-2/texto-reto1-pregunta.png';
import image70 from '../assets/images/level-2/texto-reto1-segundos.png';
import image71 from '../assets/images/level-2/texto-reto2-pregunta.png';
import image72 from '../assets/images/level-2/texto-reto2-segundos.png';
import image73 from '../assets/images/level-2/texto-ups-fallaste.png';
import image74 from '../assets/images/level-2/texto-ups-tiempo.png';

import image75 from '../assets/images/level-3/fondo-nivel3.jpg';
import image76 from '../assets/images/level-3/reto2-imagen-logo.jpg';
import image77 from '../assets/images/level-3/reto2-imagen1.jpg';
import image78 from '../assets/images/level-3/reto2-imagen2.jpg';
import image79 from '../assets/images/level-3/reto2-imagen3.jpg';
import image80 from '../assets/images/level-3/reto2-imagen4.jpg';
import image81 from '../assets/images/level-3/reto2-imagen5.jpg';
import image82 from '../assets/images/level-3/reto2-imagen6.jpg';
import image83 from '../assets/images/level-3/caja-allegra-60.png';
import image84 from '../assets/images/level-3/circulo-morado.png';
import image85 from '../assets/images/level-3/cuadro-lograste.png';
import image86 from '../assets/images/level-3/cuadro-nivel3.png';
import image87 from '../assets/images/level-3/cuadro-prueba-superada.png';
import image88 from '../assets/images/level-3/cuadro-ropa.png';
import image89 from '../assets/images/level-3/mujer-brava.png';
import image90 from '../assets/images/level-3/mujer-feliz.png';
import image91 from '../assets/images/level-3/mujer.png';
import image92 from '../assets/images/level-3/nivel3.png';
import image93 from '../assets/images/level-3/reto1-cuadro-der.png';
import image94 from '../assets/images/level-3/reto1-cuadro-izq.png';
import image95 from '../assets/images/level-3/reto1-mas.png';
import image96 from '../assets/images/level-3/reto1-solucion.png';
import image97 from '../assets/images/level-3/reto2-solucion.png';
import image98 from '../assets/images/level-3/texto-fallaste.png';
import image99 from '../assets/images/level-3/texto-ref.png';
import image100 from '../assets/images/level-3/texto-reto1-pregunta.png';
import image101 from '../assets/images/level-3/texto-reto1-segundos.png';
import image102 from '../assets/images/level-3/texto-ups.png';

import image103 from '../assets/images/level-4/fondo-nivel4.jpg';
import image104 from '../assets/images/level-4/cale-allegra-30.png';
import image105 from '../assets/images/level-4/circulo-amarillo.png';
import image106 from '../assets/images/level-4/cuadro-achis.png';
import image107 from '../assets/images/level-4/cuadro-genial.png';
import image108 from '../assets/images/level-4/cuadro-lograste.png';
import image109 from '../assets/images/level-4/cuadro-nivel4.png';
import image110 from '../assets/images/level-4/listo.png';
import image111 from '../assets/images/level-4/nina-feliz-mora.png';
import image112 from '../assets/images/level-4/nina-feliz.png';
import image113 from '../assets/images/level-4/nina-perro.png';
import image114 from '../assets/images/level-4/nina-triste-perro.png';
import image115 from '../assets/images/level-4/nina-triste.png';
import image116 from '../assets/images/level-4/nivel4.png';
import image117 from '../assets/images/level-4/reto1-espuma.png';
import image118 from '../assets/images/level-4/reto1-mano-allegra.png';
import image119 from '../assets/images/level-4/reto1-mano-jabon.png';
import image120 from '../assets/images/level-4/reto1-mano.png';
import image121 from '../assets/images/level-4/reto1-mas.png';
import image122 from '../assets/images/level-4/reto1-mora.png';
import image123 from '../assets/images/level-4/reto1-perrito-amarillo.png';
import image124 from '../assets/images/level-4/reto1-perrito-morado.png';
import image125 from '../assets/images/level-4/reto1-perritos-bano.png';
import image126 from '../assets/images/level-4/reto2-cuadro.png';
import image127 from '../assets/images/level-4/texto-fallaste.png';
import image128 from '../assets/images/level-4/texto-legal.png';
import image129 from '../assets/images/level-4/texto-perritos.png';
import image130 from '../assets/images/level-4/texto-puntos-extra.png';
import image131 from '../assets/images/level-4/texto-ref.png';
import image132 from '../assets/images/level-4/texto-reto1-pregunta.png';
import image133 from '../assets/images/level-4/texto-reto1-segundos.png';
import image134 from '../assets/images/level-4/texto-reto2-pregunta.png';
import image135 from '../assets/images/level-4/texto-reto2-segundos.png';
import image136 from '../assets/images/level-4/texto-ups.png';
import image137 from '../assets/images/level-4/texto2-reto1-pregunta.png';
import image138 from '../assets/images/level-4/banera.png';
import image139 from '../assets/images/level-4/perrito1.png';
import image140 from '../assets/images/level-4/perrito2.png';
import image141 from '../assets/images/level-4/perrito3.png';
import image142 from '../assets/images/level-4/perrito4.png';

import image143 from '../assets/images/menu/foto-nivel-1.png';
import image144 from '../assets/images/menu/foto-nivel-2.png';
import image145 from '../assets/images/menu/foto-nivel-3.png';
import image146 from '../assets/images/menu/foto-nivel-4.png';

import image147 from '../assets/images/welcome/fondo-familia.jpg';
import image148 from '../assets/images/welcome/cuadro-hola.png';
import image149 from '../assets/images/welcome/familia.png';
import image150 from '../assets/images/welcome/matera.png';
import image151 from '../assets/images/welcome/texto-selecciona.png';

import image152 from '../assets/images/common/icono-musica-off.png';
import image153 from '../assets/images/common/icono-musica-on.png';

const Preload = () => {

  if (window.cordova) {
    document.addEventListener('deviceready', () => {
      console.log('carga completa');

      setTimeout(() => {
        const preload = document.querySelector('.Preload');
        preload.classList.add('hidden');
      }, 1000);
    }, false);
  } else {
    window.onload = () => {
      console.log('carga completa');
  
      setTimeout(() => {
        const preload = document.querySelector('.Preload');
        preload.classList.add('hidden');
      }, 1000);
    }
  }

  var number = 0;

  const increment = () => {
    if (!images && !percent) {
      var images = document.querySelector('.Preload-images');
      var percent = document.querySelector('.Preload-percent');
      var imagesCount = images.childElementCount;
      var unit = 100 / imagesCount;
    }
    
    number += unit;
    percent.innerHTML = Math.ceil(number) + '%';
  }

  return (
    <React.Fragment>
      <div className='Preload'>
        <div className='Preload-images'>
          <img src={image1} alt="Preload" onLoad={() => increment()} />
          <img src={image2} alt="Preload" onLoad={() => increment()} />
          <img src={image3} alt="Preload" onLoad={() => increment()} />
          <img src={image4} alt="Preload" onLoad={() => increment()} />
          
          <img src={image5} alt="Preload" onLoad={() => increment()} />
          <img src={image6} alt="Preload" onLoad={() => increment()} />
          <img src={image7} alt="Preload" onLoad={() => increment()} />
          <img src={image8} alt="Preload" onLoad={() => increment()} />
          <img src={image9} alt="Preload" onLoad={() => increment()} />
          <img src={image10} alt="Preload" onLoad={() => increment()} />

          <img src={image11} alt="Preload" onLoad={() => increment()} />
          <img src={image12} alt="Preload" onLoad={() => increment()} />
          <img src={image13} alt="Preload" onLoad={() => increment()} />
          <img src={image14} alt="Preload" onLoad={() => increment()} />
          <img src={image15} alt="Preload" onLoad={() => increment()} />
          
          <img src={image16} alt="Preload" onLoad={() => increment()} />
          <img src={image17} alt="Preload" onLoad={() => increment()} />
          <img src={image18} alt="Preload" onLoad={() => increment()} />
          <img src={image19} alt="Preload" onLoad={() => increment()} />
          <img src={image20} alt="Preload" onLoad={() => increment()} />
          <img src={image21} alt="Preload" onLoad={() => increment()} />
          <img src={image22} alt="Preload" onLoad={() => increment()} />
          <img src={image23} alt="Preload" onLoad={() => increment()} />
          <img src={image24} alt="Preload" onLoad={() => increment()} />
          <img src={image25} alt="Preload" onLoad={() => increment()} />
          <img src={image26} alt="Preload" onLoad={() => increment()} />
          <img src={image27} alt="Preload" onLoad={() => increment()} />
          <img src={image28} alt="Preload" onLoad={() => increment()} />
          <img src={image29} alt="Preload" onLoad={() => increment()} />
          <img src={image30} alt="Preload" onLoad={() => increment()} />
          <img src={image31} alt="Preload" onLoad={() => increment()} />
          <img src={image32} alt="Preload" onLoad={() => increment()} />
          <img src={image33} alt="Preload" onLoad={() => increment()} />
          <img src={image34} alt="Preload" onLoad={() => increment()} />
          <img src={image35} alt="Preload" onLoad={() => increment()} />
          <img src={image36} alt="Preload" onLoad={() => increment()} />
          <img src={image37} alt="Preload" onLoad={() => increment()} />
          <img src={image38} alt="Preload" onLoad={() => increment()} />
          <img src={image39} alt="Preload" onLoad={() => increment()} />
          <img src={image40} alt="Preload" onLoad={() => increment()} />
          <img src={image41} alt="Preload" onLoad={() => increment()} />
          <img src={image42} alt="Preload" onLoad={() => increment()} />
          <img src={image43} alt="Preload" onLoad={() => increment()} />
          <img src={image44} alt="Preload" onLoad={() => increment()} />
          <img src={image45} alt="Preload" onLoad={() => increment()} />
          <img src={image46} alt="Preload" onLoad={() => increment()} />
          
          <img src={image47} alt="Preload" onLoad={() => increment()} />
          <img src={image48} alt="Preload" onLoad={() => increment()} />
          <img src={image49} alt="Preload" onLoad={() => increment()} />
          <img src={image50} alt="Preload" onLoad={() => increment()} />
          <img src={image51} alt="Preload" onLoad={() => increment()} />
          <img src={image52} alt="Preload" onLoad={() => increment()} />
          <img src={image53} alt="Preload" onLoad={() => increment()} />
          <img src={image54} alt="Preload" onLoad={() => increment()} />
          <img src={image55} alt="Preload" onLoad={() => increment()} />
          <img src={image56} alt="Preload" onLoad={() => increment()} />
          <img src={image57} alt="Preload" onLoad={() => increment()} />
          <img src={image58} alt="Preload" onLoad={() => increment()} />
          <img src={image59} alt="Preload" onLoad={() => increment()} />
          <img src={image60} alt="Preload" onLoad={() => increment()} />
          <img src={image61} alt="Preload" onLoad={() => increment()} />
          <img src={image62} alt="Preload" onLoad={() => increment()} />
          <img src={image63} alt="Preload" onLoad={() => increment()} />
          <img src={image64} alt="Preload" onLoad={() => increment()} />
          <img src={image65} alt="Preload" onLoad={() => increment()} />
          <img src={image66} alt="Preload" onLoad={() => increment()} />
          <img src={image67} alt="Preload" onLoad={() => increment()} />
          <img src={image68} alt="Preload" onLoad={() => increment()} />
          <img src={image69} alt="Preload" onLoad={() => increment()} />
          <img src={image70} alt="Preload" onLoad={() => increment()} />
          <img src={image71} alt="Preload" onLoad={() => increment()} />
          <img src={image72} alt="Preload" onLoad={() => increment()} />
          <img src={image73} alt="Preload" onLoad={() => increment()} />
          <img src={image74} alt="Preload" onLoad={() => increment()} />
          
          <img src={image75} alt="Preload" onLoad={() => increment()} />
          <img src={image76} alt="Preload" onLoad={() => increment()} />
          <img src={image77} alt="Preload" onLoad={() => increment()} />
          <img src={image78} alt="Preload" onLoad={() => increment()} />
          <img src={image79} alt="Preload" onLoad={() => increment()} />
          <img src={image80} alt="Preload" onLoad={() => increment()} />
          <img src={image81} alt="Preload" onLoad={() => increment()} />
          <img src={image82} alt="Preload" onLoad={() => increment()} />
          <img src={image83} alt="Preload" onLoad={() => increment()} />
          <img src={image84} alt="Preload" onLoad={() => increment()} />
          <img src={image85} alt="Preload" onLoad={() => increment()} />
          <img src={image86} alt="Preload" onLoad={() => increment()} />
          <img src={image87} alt="Preload" onLoad={() => increment()} />
          <img src={image88} alt="Preload" onLoad={() => increment()} />
          <img src={image89} alt="Preload" onLoad={() => increment()} />
          <img src={image90} alt="Preload" onLoad={() => increment()} />
          <img src={image91} alt="Preload" onLoad={() => increment()} />
          <img src={image92} alt="Preload" onLoad={() => increment()} />
          <img src={image93} alt="Preload" onLoad={() => increment()} />
          <img src={image94} alt="Preload" onLoad={() => increment()} />
          <img src={image95} alt="Preload" onLoad={() => increment()} />
          <img src={image96} alt="Preload" onLoad={() => increment()} />
          <img src={image97} alt="Preload" onLoad={() => increment()} />
          <img src={image98} alt="Preload" onLoad={() => increment()} />
          <img src={image99} alt="Preload" onLoad={() => increment()} />
          <img src={image100} alt="Preload" onLoad={() => increment()} />
          <img src={image101} alt="Preload" onLoad={() => increment()} />
          <img src={image102} alt="Preload" onLoad={() => increment()} />
          
          <img src={image103} alt="Preload" onLoad={() => increment()} />
          <img src={image104} alt="Preload" onLoad={() => increment()} />
          <img src={image105} alt="Preload" onLoad={() => increment()} />
          <img src={image106} alt="Preload" onLoad={() => increment()} />
          <img src={image107} alt="Preload" onLoad={() => increment()} />
          <img src={image108} alt="Preload" onLoad={() => increment()} />
          <img src={image109} alt="Preload" onLoad={() => increment()} />
          <img src={image110} alt="Preload" onLoad={() => increment()} />
          <img src={image111} alt="Preload" onLoad={() => increment()} />
          <img src={image112} alt="Preload" onLoad={() => increment()} />
          <img src={image113} alt="Preload" onLoad={() => increment()} />
          <img src={image114} alt="Preload" onLoad={() => increment()} />
          <img src={image115} alt="Preload" onLoad={() => increment()} />
          <img src={image116} alt="Preload" onLoad={() => increment()} />
          <img src={image117} alt="Preload" onLoad={() => increment()} />
          <img src={image118} alt="Preload" onLoad={() => increment()} />
          <img src={image119} alt="Preload" onLoad={() => increment()} />
          <img src={image120} alt="Preload" onLoad={() => increment()} />
          <img src={image121} alt="Preload" onLoad={() => increment()} />
          <img src={image122} alt="Preload" onLoad={() => increment()} />
          <img src={image123} alt="Preload" onLoad={() => increment()} />
          <img src={image124} alt="Preload" onLoad={() => increment()} />
          <img src={image125} alt="Preload" onLoad={() => increment()} />
          <img src={image126} alt="Preload" onLoad={() => increment()} />
          <img src={image127} alt="Preload" onLoad={() => increment()} />
          <img src={image128} alt="Preload" onLoad={() => increment()} />
          <img src={image129} alt="Preload" onLoad={() => increment()} />
          <img src={image130} alt="Preload" onLoad={() => increment()} />
          <img src={image131} alt="Preload" onLoad={() => increment()} />
          <img src={image132} alt="Preload" onLoad={() => increment()} />
          <img src={image133} alt="Preload" onLoad={() => increment()} />
          <img src={image134} alt="Preload" onLoad={() => increment()} />
          <img src={image135} alt="Preload" onLoad={() => increment()} />
          <img src={image136} alt="Preload" onLoad={() => increment()} />
          <img src={image137} alt="Preload" onLoad={() => increment()} />
          <img src={image138} alt="Preload" onLoad={() => increment()} />
          <img src={image139} alt="Preload" onLoad={() => increment()} />
          <img src={image140} alt="Preload" onLoad={() => increment()} />
          <img src={image141} alt="Preload" onLoad={() => increment()} />
          <img src={image142} alt="Preload" onLoad={() => increment()} />

          <img src={image143} alt="Preload" onLoad={() => increment()} />
          <img src={image144} alt="Preload" onLoad={() => increment()} />
          <img src={image145} alt="Preload" onLoad={() => increment()} />
          <img src={image146} alt="Preload" onLoad={() => increment()} />

          <img src={image147} alt="Preload" onLoad={() => increment()} />
          <img src={image148} alt="Preload" onLoad={() => increment()} />
          <img src={image149} alt="Preload" onLoad={() => increment()} />
          <img src={image150} alt="Preload" onLoad={() => increment()} />
          <img src={image151} alt="Preload" onLoad={() => increment()} />

          <img src={image152} alt="Preload" onLoad={() => increment()} />
          <img src={image153} alt="Preload" onLoad={() => increment()} />
        </div>

        <div className='Preload-overlay'>
          <div className="Preload-icon" />
          <div className='Preload-percent'>0</div>
        </div>
      </div>
    </React.Fragment>
  ); 
}

export default Preload;