import React from 'react'
import { useNavigate } from 'react-router-dom'
import AppContext from '../context/AppContext';
import State from '../components/State';
import Overlay from '../components/Overlay';
import Reloj from '../components/Reloj';
import useAudio from '../hooks/useAudio';

import logoAllegra from '../assets/images/common/logo-allegra.png';
import jugar from '../assets/images/home/boton-jugar.png';
import nivel from '../assets/images/level-3/nivel3.png';
import mujer from '../assets/images/level-3/mujer.png';
import titulo from '../assets/images/level-3/cuadro-nivel3.png';
import producto from '../assets/images/level-3/caja-allegra-60.png';

import mujerBrava from '../assets/images/level-3/mujer-brava.png';
import mensaje from '../assets/images/level-3/cuadro-ropa.png';

import diferenciasPregunta from '../assets/images/level-3/texto-reto1-pregunta.png';
import diferenciasCuadro1 from '../assets/images/level-3/reto1-cuadro-der.png';
import diferenciasCuadro2 from '../assets/images/level-3/reto1-cuadro-izq.png';
import diferenciasLograste from '../assets/images/level-3/cuadro-lograste.png';
import diferenciasUpsTiempo from '../assets/images/level-3/texto-ups.png';
import diferenciasSegundos from '../assets/images/level-3/texto-reto1-segundos.png';
import mujerFeliz from '../assets/images/level-3/mujer-feliz.png';

import concentresePregunta from '../assets/images/level-3/texto-reto2-pregunta.png';
import concentreseSuperada from '../assets/images/level-3/cuadro-prueba-superada.png';
import concentreseSegundos from '../assets/images/level-3/texto-reto1-segundos.png';

import referencias from '../assets/images/level-3/texto-ref.png';
import cerrar from '../assets/images/common/boton-cerrar.png';

import iconoMusicaOn from '../assets/images/common/icono-musica-on.png';
import iconoMusicaOff from '../assets/images/common/icono-musica-off.png';

const Level3 = () => {
  const { state, setState } = React.useContext(AppContext);
  const navigate = useNavigate();
  const audio = useAudio();

  console.log('level3');

  /**
   * HANDLE STATE
   * 
   * @param {string} screen   Guarda la pantalla actual
   * @param {string} clock    Dice si el reloj esta o no activo
   */

  const handleState = (screen) => {
    setState({
      ...state,
      screen: screen,
      // clock: clock,
    })
  }

  /**
   * HANDLE NAVIGATE
   * Cierra el modal
   * Abre la ruta solicitada
   * @param {string} url    Ruta a la cual se dirige
   */

   const handleNavigate = (url) => {
    setState({
      ...state,
      screen: false,
    })
    navigate('/'+url, { replace: true });
  }
  
  /**
   * HANDLE DIFERENCIAS
   * Pinta el elemento seleccionado
   * Gana si encuentra todas los elementos correctas
   * @param {event} e Evento del click
   */
  
  const handleDiferencias = (e) => {
    e.target.classList.add('active');
    e.target.classList.add('zoomIn');
    
    const elementsOks = 5;
    const elementsActived = document.querySelectorAll('.Level3-diferencias-elemento.active');

    const scoreNumber = document.querySelector('.Level3-diferencias-score-number');
    scoreNumber.innerHTML = elementsActived.length;
    
    if (elementsOks === elementsActived.length) {
      console.log('ganaste');

      setTimeout(() => {
        handleState('diferencias-ok');
      }, 1000);
    }
  }

  /**
   * CARDS
   * Juego concentrese
   */

  let cards;

  let cardsInit = ['01', '02', '03', '04', '05', '06', '01', '02', '03', '04', '05', '06'];
  cards = cardsInit.sort(() => Math.random() - 0.5);

  console.log(cards);

  const cardsOk = [];
  console.log('cardsOk:', cardsOk);

  if (cardsOk.length > 0) {
    setTimeout(() => {
      cardsOk.forEach(cardOk => {
        const cardOks = document.getElementsByName(cardOk);
        console.log('cardOks:', cardOks);

        cardOks.forEach(element => {
          element.classList.add('card-ok');
        });
      })
    }, 200);
  }

  let cardSelected = {};

  const handleCard = (card, index) => {
    console.log('card: ' + card, 'index: ' + index);
    console.log('cardSelected:', Object.keys(cardSelected).length);

    const currentCard = document.getElementById(index);

    if (currentCard.classList.contains('card-ok') || currentCard.classList.contains('card-active')) {
      console.log('card ya jugo');
      return;
    }

    const lock = document.querySelector('.Level3-concentrese-lock');

    const cardActive = document.getElementById(index);
    cardActive.classList.add('card-active');

    if (Object.keys(cardSelected).length !== 0) {
      if (cardSelected.card === card && cardSelected.index !== index) {
        console.log('Bien!');
        lock.style.display = 'block';

        cardsOk.push(card);
        console.log(cardsOk);

        setTimeout(() => {
          const cardsActiveds = document.querySelectorAll('.card-active');
          cardsActiveds.forEach(card => {
            card.classList.remove('card-active');
            card.classList.add('card-ok');
            lock.style.display = 'none';

            if (cardsOk.length === 6) {
              audio.play('nice');
              audio.stop('tickTock');
              handleState('concentrese-ok');
            }
          });
        }, 1000);
      } else {
        console.log('Mal');
        lock.style.display = 'block';

        setTimeout(() => {
          const cardsActiveds = document.querySelectorAll('.card-active');
          cardsActiveds.forEach(card => {
            card.classList.remove('card-active');
            lock.style.display = 'none';
          });
        }, 1000);
      }
      cardSelected = {};
    } else {
      cardSelected = { card, index };
    }
  }

  /**
   * HANDLE MUSIC
   * Inicia la musica si no esta sonando
   */

   const handleMusic = () => {
    console.log('evaluando musica');
    const music = document.querySelector('.audio-music')

    if (music.paused) {
      console.log('musica esta quieta');
      audio.restart('music');
    } else {
      console.log('musica esta sonando');
    }
  }

  /**
   * SWITCH MUSIC
   * Enciende o apaga la musica
   */

  const switchMusic = (state) => {
    const iconoMusica = document.querySelector('.Home-icono-musica');

    if (iconoMusica) {
      if (state === 'on') {
        audio.volumeOn('music');
        iconoMusica.src = iconoMusicaOn;
        localStorage.setItem('musicAlegra', 'on');
      } else if (state === 'off') {
        audio.volumeOff('music');
        iconoMusica.src = iconoMusicaOff;
        localStorage.setItem('musicAlegra', 'off');
      } else if (state === 'toggle') {
        if (audio.isPlay('music')) {
          if (localStorage.getItem('musicAlegra') === 'on') {
            switchMusic('off');
          } else if (localStorage.getItem('musicAlegra') === 'off') {
            switchMusic('on');
          }
        } else {
          audio.restart('music');
          switchMusic('on');
        }
      }
    }
  }

  setTimeout(() => {
    if (audio.isPlay('music')) {
      if (localStorage.getItem('musicAlegra') === 'on') {
        switchMusic('on');
      } else if (localStorage.getItem('musicAlegra') === 'off') {
        switchMusic('off');
      }
    } else {
      switchMusic('off');
    }    
  }, 500);

  /**
   * SAVE SCORE
   * Guarda el puntaje en el servidor
   */

  const saveScore = () => {
    const score = {
      name: localStorage.getItem('fullname'),
      pharmacy: localStorage.getItem('pharmacy'),
      score: Number(localStorage.getItem('gameDiferenciasScore')) + Number(localStorage.getItem('gameConcentreseScore')),
      time: Number(localStorage.getItem('gameDiferenciasTime')) + Number(localStorage.getItem('gameConcentreseTime')),
    }
    
    if (localStorage.getItem('gameDiferencias') === 'finish' && localStorage.getItem('gameConcentrese') === 'finish') {
      console.log('Save score!');
      
      const rankingAllegraLevel3 = localStorage.getItem('rankingAllegraLevel3');
  
      if (!rankingAllegraLevel3) {
        console.log('no existe ranking');
        localStorage.setItem('rankingAllegraLevel3', JSON.stringify([score]));
      } else {
        console.log('ya existe ranking');
        const ranking = JSON.parse(localStorage.getItem('rankingAllegraLevel3'));
        ranking.push(score);
  
        localStorage.setItem('rankingAllegraLevel3', JSON.stringify(ranking));
      }

      localStorage.setItem('gameDiferencias', 'saved');
      localStorage.setItem('gameConcentrese', 'saved');
    }
  }

  /**
   * USE EFFECT
   * Acciones iniciales
   */

  // React.useEffect(() => {
  //   handleState('seccion4-ok');
  // }, []);

  return (
    <div className='Level3'>
      <State />
      <div className='Level3-background fadeIn' />
      <img className='Level3-logo-allegra' src={logoAllegra} alt="Logo Alegra" />
      <img className='Level3-nivel' src={nivel} alt="Nivel" />
      <button className='button right bottom' onClick={() => { 
        handleNavigate('welcome'); 
        audio.play('modalOut') 
      }} >MENU</button>
      <img className='Home-icono-musica absolute left bottom' src={iconoMusicaOn} alt="Musica" onClick={() => switchMusic('toggle')} />

      {/* Presentacion */}

      {!state.screen &&
        <React.Fragment>
          { localStorage.setItem('gameDiferencias', 'ready') }
          { localStorage.setItem('gameDiferenciasScore', 0) }
          { localStorage.setItem('gameDiferenciasTime', 0) }
          { localStorage.setItem('gameConcentrese', 'ready') }
          { localStorage.setItem('gameConcentreseScore', 0) }
          { localStorage.setItem('gameConcentreseTime', 0) }

          <img className='Level3-mujer slideUp' src={mujer} alt="Mujer" />
          <img className='Level3-producto zoomIn' src={producto} alt="Producto" />
          <img className='Level3-titulo' src={titulo} alt="Titulo" />
          <img className='Home-jugar-btn' src={jugar} alt="Jugar" onClick={() => { 
            handleState('instrucciones');
            handleMusic();
            audio.play('button');
          }} />
        </React.Fragment>
      }

      {/* Instrucciones */}

      {state.screen === 'instrucciones' &&
        <React.Fragment>
          <img className='Level3-mujer-brava' src={mujerBrava} alt="Mujer" />
          <img className='Level3-mensaje zoomIn' src={mensaje} alt="Titulo" onClick={() => { 
            handleState('diferencias');
            audio.play('button');
          }} />
        </React.Fragment>
      }

      {/* Diferencias */}

      {state.screen === 'diferencias' &&
        <Overlay>
          <div className="Level3-diferencias-wrapper">
            <img className='Level3-diferencias-pregunta' src={diferenciasPregunta} alt="Pregunta" />
            <div className="Level3-diferencias-cuadros">
              <div className='Level3-diferencias-cuadro1-wrapper'>
                <img className='Level3-diferencias-cuadro1' src={diferenciasCuadro1} alt="Cuadro 1" />
                <div className='Level3-diferencias-elemento Level3-diferencias-elemento-1' onClick={(e) => handleDiferencias(e)} />
                <div className='Level3-diferencias-elemento Level3-diferencias-elemento-2' onClick={(e) => handleDiferencias(e)} />
                <div className='Level3-diferencias-elemento Level3-diferencias-elemento-3' onClick={(e) => handleDiferencias(e)} />
                <div className='Level3-diferencias-elemento Level3-diferencias-elemento-4' onClick={(e) => handleDiferencias(e)} />
                <div className='Level3-diferencias-elemento Level3-diferencias-elemento-5' onClick={(e) => handleDiferencias(e)} />
              </div>
              <div className='Level3-diferencias-cuadro2-wrapper'>
                <img className='Level3-diferencias-cuadro2' src={diferenciasCuadro2} alt="Cuadro 2" />
              </div>
            </div>
            <div className='Level3-diferencias-score'>
              <span className='Level3-diferencias-score-number'>0</span>/5
            </div>
          </div>
          <button className='button right bottom z-200' onClick={() => { 
            handleState(''); 
            audio.play('modalOut') 
          }} >SALIR</button>
          
          <img className='Level3-diferencias-segundos' src={diferenciasSegundos} alt="Segundos Reto" />
          <Reloj timeOut="60" timeOutScreen={'diferencias-timeout'} game="Diferencias" />
        </Overlay>
      }

      {/* Diferencias - Callbacks */}

      {state.screen === 'diferencias-timeout' &&
        <Overlay>
          <img className='Level3-diferencias-mujer-brava slideRight' src={mujerBrava} alt="Mujer" />
          <img className='Level3-diferencias-ups-tiempo' src={diferenciasUpsTiempo} alt="Timeout" onClick={() => {
            handleState('diferencias');
            audio.play('modalOut');
          }} />
        </Overlay>
      }

      {state.screen === 'diferencias-ok' &&
        <Overlay>
          { localStorage.getItem('gameDiferencias') === 'ready' && localStorage.setItem('gameDiferencias', 'finish') }
          { localStorage.setItem('gameDiferenciasScore', 5) }

          <img className='Level3-diferencias-mujer-feliz slideUp' src={mujerFeliz} alt="Hombre" />
          <img className='Level3-diferencias-lograste zoomIn' src={diferenciasLograste} alt="Bien" onClick={() => {
            handleState('concentrese');
            audio.play('button');
          }} />
        </Overlay>
      }

      {/* Concentrese */}

      {state.screen === 'concentrese' &&
        <Overlay>
          <div className="Level3-concentrese-wrapper">
            <img className='Level3-concentrese-pregunta' src={concentresePregunta} alt="Pregunta" />
            <div className='Level3-concentrese-paletas zoomIn'>
            {cards.map((card, index) => (
              <div className='Level3-card' key={index} id={index} name={card} onClick={() => {
                handleCard(card, index);
                audio.play('card');
              }} />
            ))}
          </div>
          <div className='Level3-concentrese-lock' />
          </div>
          <button className='button right bottom z-200' onClick={() => { 
            handleState(''); 
            audio.play('modalOut') 
          }} >SALIR</button>
          
          <img className='Level3-concentrese-segundos' src={concentreseSegundos} alt="Segundos Reto" />
          <Reloj timeOut="60" timeOutScreen={'concentrese-timeout'} game="Concentrese" />
        </Overlay>
      }

      {/* Concentrese - Callbacks */}

      {state.screen === 'concentrese-timeout' &&
        <Overlay>
          <img className='Level3-concentrese-mujer-brava slideRight' src={mujerBrava} alt="Hombre" />
          <img className='Level3-concentrese-ups-tiempo' src={diferenciasUpsTiempo} alt="Timeout" onClick={() => {
            handleState('concentrese');
            audio.play('modalOut');
          }} />
        </Overlay>
      }

      {state.screen === 'concentrese-ok' &&
        <Overlay>
          { localStorage.getItem('gameConcentrese') === 'ready' && localStorage.setItem('gameConcentrese', 'finish') }
          { localStorage.setItem('gameConcentreseScore', 6) }

          { saveScore() }

          <img className='Level3-concentrese-mujer-feliz slideUp' src={mujerFeliz} alt="Mujer" />
          <div className="Level3-concentrese-superada-wrapper zoomIn">
            <img className='Level3-concentrese-superada' src={concentreseSuperada} alt="Superada" />
            <div className='Level3-concentrese-continuar' onClick={() => {
              handleNavigate('level4');
              audio.play('button');
            }} />
            <div className='Level3-concentrese-menu' onClick={() => {
              handleNavigate('welcome');
              audio.play('button');
            }} />
            <div className='Level3-concentrese-referencias' onClick={() => {
              handleState('referencias');
              audio.play('button');
            }} />
          </div>
          <button className='button right bottom z-200' onClick={() => { 
            handleNavigate('finish');
            audio.play('modalOut');
          }} >CALIFÍCANOS</button>
        </Overlay>
      }

      {/* Referencias */}

      {state.screen === 'referencias' &&
        <Overlay>
          <div>
            <img className='Level3-referencias-close' src={cerrar} alt="Cerrar" onClick={() => {
              handleState('concentrese-ok');
              audio.play('modalOut');
            }} />
            <img className='Level3-referencias' src={referencias} alt='Referencias' />
          </div>
        </Overlay>
      }
    </div>
  );
}

export default Level3;