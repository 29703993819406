import React from 'react'
import { useNavigate } from 'react-router-dom'

import logo from '../assets/images/common/logo-allegra.png';
import level1_img from '../assets/images/menu/foto-nivel-1.png';
import level2_img from '../assets/images/menu/foto-nivel-2.png';
import level3_img from '../assets/images/menu/foto-nivel-3.png';
import level4_img from '../assets/images/menu/foto-nivel-4.png';

const Ranking = () => {
  const navigate = useNavigate();

  console.log('ranking');

  setTimeout(() => {
    const ranking1 = JSON.parse(localStorage.getItem('rankingAllegraLevel1'));
    const ranking2 = JSON.parse(localStorage.getItem('rankingAllegraLevel2'));
    const ranking3 = JSON.parse(localStorage.getItem('rankingAllegraLevel3'));
    const ranking4 = JSON.parse(localStorage.getItem('rankingAllegraLevel4'));

    if (ranking1) {
      ranking1.sort((a, b) => {
        if (Number(a.score) > Number(b.score)) { return -1 }
        if (Number(a.score) < Number(b.score)) { return 1 }
        if (Number(a.time) > Number(b.time)) { return 1 }
        if (Number(a.time) < Number(b.time)) { return -1 }
        return 0;
      })
      
      ranking1.forEach((score, index) => {
        printScore1(index, score.name, score.pharmacy, score.score, score.time);
      });
    }

    if (ranking2) {
      ranking2.sort((a, b) => {
        if (Number(a.score) > Number(b.score)) { return -1 }
        if (Number(a.score) < Number(b.score)) { return 1 }
        if (Number(a.time) > Number(b.time)) { return 1 }
        if (Number(a.time) < Number(b.time)) { return -1 }
        return 0;
      })

      ranking2.forEach((score, index) => {
        printScore2(index, score.name, score.pharmacy, score.score, score.time);
      });
    }

    if (ranking3) {
      ranking3.sort((a, b) => {
        if (Number(a.score) > Number(b.score)) { return -1 }
        if (Number(a.score) < Number(b.score)) { return 1 }
        if (Number(a.time) > Number(b.time)) { return 1 }
        if (Number(a.time) < Number(b.time)) { return -1 }
        return 0;
      })

      ranking3.forEach((score, index) => {
        printScore3(index, score.name, score.pharmacy, score.score, score.time);
      });
    }

    if (ranking4) {
      ranking4.sort((a, b) => {
        if (Number(a.score) > Number(b.score)) { return -1 }
        if (Number(a.score) < Number(b.score)) { return 1 }
        if (Number(a.time) > Number(b.time)) { return 1 }
        if (Number(a.time) < Number(b.time)) { return -1 }
        return 0;
      })

      ranking4.forEach((score, index) => {
        printScore4(index, score.name, score.pharmacy, score.score, score.time);
      });
    }
  }, 1000);

  const printScore1 = (position, name, pharmacy, score, time) => {
    const scoreTemplate = /*html*/ `
      <tr>
        <td>${position + 1}</td>
        <td>${name}</td>
        <td>${pharmacy}</td>
        <td>${time}</td>
        <td>${score}</td>
      </tr>
    `;

    const rankingList = document.querySelector('.Ranking1 .Ranking-list');

    rankingList.innerHTML += scoreTemplate
  }

  const printScore2 = (position, name, pharmacy, score, time) => {
    const scoreTemplate = /*html*/ `
      <tr>
        <td>${position + 1}</td>
        <td>${name}</td>
        <td>${pharmacy}</td>
        <td>${time}</td>
        <td>${score}</td>
      </tr>
    `;

    const rankingList = document.querySelector('.Ranking2 .Ranking-list');

    rankingList.innerHTML += scoreTemplate
  }

  const printScore3 = (position, name, pharmacy, score, time) => {
    const scoreTemplate = /*html*/ `
      <tr>
        <td>${position + 1}</td>
        <td>${name}</td>
        <td>${pharmacy}</td>
        <td>${time}</td>
        <td>${score}</td>
      </tr>
    `;

    const rankingList = document.querySelector('.Ranking3 .Ranking-list');

    rankingList.innerHTML += scoreTemplate
  }

  const printScore4 = (position, name, pharmacy, score, time) => {
    const scoreTemplate = /*html*/ `
      <tr>
        <td>${position + 1}</td>
        <td>${name}</td>
        <td>${pharmacy}</td>
        <td>${time}</td>
        <td>${score}</td>
      </tr>
    `;

    const rankingList = document.querySelector('.Ranking4 .Ranking-list');

    rankingList.innerHTML += scoreTemplate
  }
  
  const showTable = (id) => {
    const buttons = document.querySelectorAll('.Ranking-button');
    const tables = document.querySelectorAll('.Ranking-table');

    buttons.forEach(btn => {
        btn.classList.remove('active');
    })

    tables.forEach(table => {
      table.classList.remove('active');
    })

    const button = document.querySelector('.Ranking-button.Ranking'+id);
    const table = document.querySelector('.Ranking-table.Ranking'+id);

    button.classList.add('active');
    table.classList.add('active');
  }

  return (
    <div className='Ranking'>
      <div className='Ranking-background' />
      <img className='Ranking-logo-allegra' src={logo} alt="Logo Allegra" />
      <button className='button left top' onClick={() => {
        navigate('/welcome', { replace: true });
      }} >MENU</button>

      <div className='Ranking-wrapper'>
        <p className='Ranking-title'>RANKING</p>

        <div className='Ranking-menu'>
          <div className='Ranking-button Ranking1 active' onClick={() => showTable(1)}>
            <img className='Ranking-level-img' src={level1_img} alt="Nivel 1" />
            <span>Nivel 1</span>
          </div>
          <div className='Ranking-button Ranking2' onClick={() => showTable(2)}>
            <img className='Ranking-level-img' src={level2_img} alt="Nivel 2" />
            <span>Nivel 2</span>
          </div>
          <div className='Ranking-button Ranking3' onClick={() => showTable(3)}>
            <img className='Ranking-level-img' src={level3_img} alt="Nivel 3" />
            <span>Nivel 3</span>
          </div>
          <div className='Ranking-button Ranking4' onClick={() => showTable(4)}>
            <img className='Ranking-level-img' src={level4_img} alt="Nivel 4" />
            <span>Nivel 4</span>
          </div>
        </div>

        <div className='Ranking-table-wrapper zoomIn'>
          <table className='Ranking-table Ranking1 active' cellPadding="5">
            <thead>
              <tr>
                <th>Posición</th>
                <th>Nombre</th>
                <th>Farmacia</th>
                <th>Tiempo</th>
                <th>Puntaje</th>
              </tr>
            </thead>
            <tbody className='Ranking-list'>
            </tbody>
          </table>

          <table className='Ranking-table Ranking2' cellPadding="5">
            <thead>
              <tr>
                <th>Posición</th>
                <th>Nombre</th>
                <th>Farmacia</th>
                <th>Tiempo</th>
                <th>Puntaje</th>
              </tr>
            </thead>
            <tbody className='Ranking-list'>
            </tbody>
          </table>

          <table className='Ranking-table Ranking3' cellPadding="5">
            <thead>
              <tr>
                <th>Posición</th>
                <th>Nombre</th>
                <th>Farmacia</th>
                <th>Tiempo</th>
                <th>Puntaje</th>
              </tr>
            </thead>
            <tbody className='Ranking-list'>
            </tbody>
          </table>

          <table className='Ranking-table Ranking4' cellPadding="5">
            <thead>
              <tr>
                <th>Posición</th>
                <th>Nombre</th>
                <th>Farmacia</th>
                <th>Tiempo</th>
                <th>Puntaje</th>
              </tr>
            </thead>
            <tbody className='Ranking-list'>
            </tbody>
          </table>
        </div>
      </div>
      
    </div>
  );
}

export default Ranking;