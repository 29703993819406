import React from 'react'

import music from '../assets/audio/cute_avalanche_rkvc.mp3';
import button from '../assets/audio/confirmation_001.ogg.mp3';
import tickTock from '../assets/audio/tick-tock.mp3';
import modalIn from '../assets/audio/laser1.ogg.mp3';
import modalOut from '../assets/audio/click_001.ogg.mp3';
import youLose from '../assets/audio/you_lose.ogg.mp3';
import youWin from '../assets/audio/you_win.ogg.mp3';
import wrong from '../assets/audio/jingles_PIZZI01.ogg.mp3';
import nice from '../assets/audio/jingles_STEEL16.ogg.mp3';
import correct from '../assets/audio/correct.ogg.mp3';
import go from '../assets/audio/go.ogg.mp3';
import goGoGo from '../assets/audio/war_go_go_go.ogg.mp3';
import timeOver from '../assets/audio/time_over.ogg.mp3';
import reloading from '../assets/audio/war_reloading.ogg.mp3';
import gameOver from '../assets/audio/game_over.ogg.mp3';
import jump from '../assets/audio/phaserDown2.ogg.mp3';
import bending from '../assets/audio/click_005.ogg.mp3';
import recharge from '../assets/audio/confirmation_002.ogg.mp3';
import hit from '../assets/audio/jab-jab.mp3';
import card from '../assets/audio/drop_004.ogg.mp3';
import shot from '../assets/audio/laser1.ogg.mp3';
import bubble from '../assets/audio/drop_002.ogg.mp3';
import fadeOut from '../assets/audio/drop_003.ogg.mp3';

const Audio = () => {
  return (
    <React.Fragment>
      <audio src={music} className="audio-music" preload="true" loop />
      <audio src={button} className="audio-button" preload="true" />
      <audio src={tickTock} className="audio-tickTock" preload="true" loop />
      <audio src={modalIn} className="audio-modalIn" preload="true" />
      <audio src={modalOut} className="audio-modalOut" preload="true" />
      <audio src={youLose} className="audio-youLose" preload="true" />
      <audio src={youWin} className="audio-youWin" preload="true" />
      <audio src={wrong} className="audio-wrong" preload="true" />
      <audio src={nice} className="audio-nice" preload="true" />
      <audio src={correct} className="audio-correct" preload="true" />
      <audio src={go} className="audio-go" preload="true" />
      <audio src={goGoGo} className="audio-goGoGo" preload="true" />
      <audio src={timeOver} className="audio-timeOver" preload="true" />
      <audio src={reloading} className="audio-reloading" preload="true" />
      <audio src={gameOver} className="audio-gameOver" preload="true" />
      <audio src={jump} className="audio-jump" preload="true" />
      <audio src={bending} className="audio-bending" preload="true" />
      <audio src={recharge} className="audio-recharge" preload="true" />
      <audio src={hit} className="audio-hit" preload="true" />
      <audio src={card} className="audio-card" preload="true" />
      <audio src={shot} className="audio-shot" preload="true" />
      <audio src={bubble} className="audio-bubble" preload="true" />
      <audio src={fadeOut} className="audio-fadeOut" preload="true" />
    </React.Fragment>
  ); 
}

export default Audio;