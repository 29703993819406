import React from 'react'
import { useNavigate } from 'react-router-dom'
import AppContext from '../context/AppContext';
import State from '../components/State';
import Overlay from '../components/Overlay';
import useAudio from '../hooks/useAudio';

import logo from '../assets/images/common/logo-allegra.png';
import familia from '../assets/images/welcome/familia.png';
import cuadro from '../assets/images/welcome/cuadro-hola.png';
import matera from '../assets/images/welcome/matera.png';
import titleLevels from '../assets/images/welcome/texto-selecciona.png';
import level1_img from '../assets/images/menu/foto-nivel-1.png';
import level2_img from '../assets/images/menu/foto-nivel-2.png';
import level3_img from '../assets/images/menu/foto-nivel-3.png';
import level4_img from '../assets/images/menu/foto-nivel-4.png';

import iconoMusicaOn from '../assets/images/common/icono-musica-on.png';
import iconoMusicaOff from '../assets/images/common/icono-musica-off.png';

const Welcome = () => {
  const { state, setState } = React.useContext(AppContext);
  const navigate = useNavigate();
  const audio = useAudio();

  console.log('welcome');

  /**
   * HANDLE STATE
   * 
   * @param {string} screen   Guarda la pantalla actual
   */

   const handleState = (screen) => {
    setState({
      ...state,
      screen: screen,
    })
  }
  
  /**
   * HANDLE NAVIGATE
   * Cierra el modal
   * Abre la ruta solicitada
   * @param {string} url    Ruta a la cual se dirige
   */

  const handleNavigate = (url) => {
    setState({
      ...state,
      screen: '',
    })
    navigate('/'+url, { replace: true });
  }

  /**
   * HANDLE MUSIC
   * Inicia la musica si no esta sonando
   */

  const handleMusic = () => {
    console.log('evaluando musica');
    const music = document.querySelector('.audio-music')

    if (music.paused) {
      console.log('musica esta quieta');
      audio.restart('music');
    } else {
      console.log('musica esta sonando');
    }
  }

  /**
   * SWITCH MUSIC
   * Enciende o apaga la musica
   */

  const switchMusic = (state) => {
    const iconoMusica = document.querySelector('.Home-icono-musica');

    if (iconoMusica) {
      if (state === 'on') {
        audio.volumeOn('music');
        iconoMusica.src = iconoMusicaOn;
        localStorage.setItem('musicAlegra', 'on');
      } else if (state === 'off') {
        audio.volumeOff('music');
        iconoMusica.src = iconoMusicaOff;
        localStorage.setItem('musicAlegra', 'off');
      } else if (state === 'toggle') {
        if (audio.isPlay('music')) {
          if (localStorage.getItem('musicAlegra') === 'on') {
            switchMusic('off');
          } else if (localStorage.getItem('musicAlegra') === 'off') {
            switchMusic('on');
          }
        } else {
          audio.restart('music');
          switchMusic('on');
        }
      }
    }
  }

  setTimeout(() => {
    if (audio.isPlay('music')) {
      if (localStorage.getItem('musicAlegra') === 'on') {
        switchMusic('on');
      } else if (localStorage.getItem('musicAlegra') === 'off') {
        switchMusic('off');
      }
    } else {
      switchMusic('off');
    }    
  }, 500);

  return (
    <React.Fragment>
      <div className='Welcome'>
        <State />
        <div className='Welcome-background' />
        <img className='Welcome-logo-allegra' src={logo} alt="Logo Allegra" />
        <img className='Welcome-familia slideRight' src={familia} alt="Familia" />
        <img className='Welcome-cuadro zoomIn' src={cuadro} alt="Cuadro" onClick={() => { handleState('menu'); audio.play('button'); handleMusic() }} />
        <img className='Welcome-matera' src={matera} alt="Matera" />
        <div className='Welcome-userdata'>
          <button className='Welcome-logout-btn' onClick={() => { 
            handleNavigate(''); 
            audio.fadeOut('music');
            
            localStorage.removeItem('pharmacy');
            localStorage.removeItem('fullname');

            localStorage.removeItem('gamePolvo');
            localStorage.removeItem('gamePolvoScore');
            localStorage.removeItem('gamePolvoTime');
            localStorage.removeItem('gameCrucigrama');
            localStorage.removeItem('gameCrucigramaScore');
            localStorage.removeItem('gameCrucigramaTime');
            localStorage.removeItem('gameEscondidas');
            localStorage.removeItem('gameEscondidasScore');
            localStorage.removeItem('gameEscondidasTime');

            localStorage.removeItem('gameSintomas');
            localStorage.removeItem('gameSintomasScore');
            localStorage.removeItem('gameSintomasTime');
            localStorage.removeItem('gameLaberinto');
            localStorage.removeItem('gameLaberintoScore');
            localStorage.removeItem('gameLaberintoTime');

            localStorage.removeItem('gameDiferencias');
            localStorage.removeItem('gameDiferenciasScore');
            localStorage.removeItem('gameDiferenciasTime');
            localStorage.removeItem('gameConcentrese');
            localStorage.removeItem('gameConcentreseScore');
            localStorage.removeItem('gameConcentreseTime');

            localStorage.removeItem('gameBano');
            localStorage.removeItem('gameBanoScore');
            localStorage.removeItem('gameBanoTime');
            localStorage.removeItem('gameLetras');
            localStorage.removeItem('gameLetrasScore');
            localStorage.removeItem('gameLetrasTime');
          }}>Cerrar sesión</button>
          <div className='Welcome-username'>{localStorage.getItem('fullname')}</div>
        </div>
        <button className='Welcome-ranking-btn' onClick={() => { 
          handleNavigate('ranking'); 
        }}>Ranking</button>
        <img className='Home-icono-musica absolute left bottom' src={iconoMusicaOn} alt="Musica" onClick={() => switchMusic('toggle')} />

        {state.screen === 'menu' && 
          <Overlay>
            <div className='Welcome-modal'>
              <img className='Welcome-title-levels' src={titleLevels} alt="Titulo Niveles" />
              <div className='Welcome-levels'>
                <img className='Welcome-level-img' src={level1_img} alt="Nivel 1" onClick={() => { handleNavigate('level1'); audio.play('button') }} />
                <img className='Welcome-level-img' src={level2_img} alt="Nivel 2" onClick={() => { handleNavigate('level2'); audio.play('button') }} />
                <img className='Welcome-level-img' src={level3_img} alt="Nivel 3" onClick={() => { handleNavigate('level3'); audio.play('button') }} />
                <img className='Welcome-level-img' src={level4_img} alt="Nivel 4" onClick={() => { handleNavigate('level4'); audio.play('button') }} />
              </div>
            </div>
            <button className='button over right top' onClick={() => { handleState(''); audio.play('modalOut') }} >X</button>
          </Overlay>
        }
      </div>
    </React.Fragment>
  );
}

export default Welcome;