import React from 'react'
import { useNavigate } from 'react-router-dom'
import AppContext from '../context/AppContext';
import gsap from 'gsap';
import Draggable from 'gsap/Draggable';
import State from '../components/State';
import Overlay from '../components/Overlay';
import Reloj from '../components/Reloj';
import useAudio from '../hooks/useAudio';

import logoAllegra from '../assets/images/common/logo-allegra.png';
import jugar from '../assets/images/home/boton-jugar.png';
import nivel from '../assets/images/level-2/nivel2.png';
import hombreFeliz from '../assets/images/level-2/hombre-feliz.png';
import titulo from '../assets/images/level-2/cuadro-nivel2.png';
import producto from '../assets/images/level-2/caja-allegra-180.png';

import hombreEnfermo from '../assets/images/level-2/hombre-enfermo.png';
import mensaje from '../assets/images/level-2/cuadro-pica.png';

import sintomasPregunta from '../assets/images/level-2/texto-reto1-pregunta.png';
import sintomasRespuesta1 from '../assets/images/level-2/reto1-cuadro1.png';
import sintomasRespuesta2 from '../assets/images/level-2/reto1-cuadro2.png';
import sintomasRespuesta3 from '../assets/images/level-2/reto1-cuadro3.png';
import sintomasRespuesta4 from '../assets/images/level-2/reto1-cuadro4.png';
import sintomasRespuesta5 from '../assets/images/level-2/reto1-cuadro5.png';
import sintomasRespuesta6 from '../assets/images/level-2/reto1-cuadro6.png';
import sintomasExcelente from '../assets/images/level-2/cuadro-excelente.png';
import sintomasUpsFallaste from '../assets/images/level-2/texto-ups-fallaste.png';
import sintomasUpsTiempo from '../assets/images/level-2/texto-ups-tiempo.png';
import sintomasSegundos from '../assets/images/level-2/texto-reto1-segundos.png';

import laberintoPregunta from '../assets/images/level-2/texto-reto2-pregunta.png';
import laberintoPlano from '../assets/images/level-2/reto2-laberinto.png';
import laberintoBien from '../assets/images/level-2/cuadro-bien-hecho.png';
import laberintoUpsTiempo from '../assets/images/level-2/texto-ups-tiempo.png';
import laberintoSegundos from '../assets/images/level-2/texto-reto2-segundos.png';

import referencias from '../assets/images/level-2/texto-ref.png';
import cerrar from '../assets/images/common/boton-cerrar.png';

import iconoMusicaOn from '../assets/images/common/icono-musica-on.png';
import iconoMusicaOff from '../assets/images/common/icono-musica-off.png';

const Level2 = () => {
  const { state, setState } = React.useContext(AppContext);
  const navigate = useNavigate();
  const audio = useAudio();
  gsap.registerPlugin(Draggable);

  console.log('level2');

  /**
   * HANDLE STATE
   * 
   * @param {string} screen   Guarda la pantalla actual
   * @param {string} clock    Dice si el reloj esta o no activo
   */

  const handleState = (screen) => {
    setState({
      ...state,
      screen: screen,
      // clock: clock,
    })
  }

  /**
   * HANDLE NAVIGATE
   * Cierra el modal
   * Abre la ruta solicitada
   * @param {string} url    Ruta a la cual se dirige
   */

   const handleNavigate = (url) => {
    setState({
      ...state,
      screen: false,
    })
    navigate('/'+url, { replace: true });
  }

  // LABERINTO

  // Posicion inicial de la bolita 
  let posX = 1;
  let posY = 6;

  if (state.screen === 'laberinto') {
    setTimeout(() => {
      const grid = document.querySelector('.Level2-laberinto-grid');
  
      // Crea las rutas 
      way.forEach(element => {
        let wayBox = document.createElement('div');
        wayBox.style.gridArea = element;
        wayBox.style.opacity = 1;
        wayBox.classList.add('Level2-laberinto-way-box');
        grid.append(wayBox);
      });
  
      // Posiciona la bolita
      let bolita = document.querySelector('.Level2-laberinto-bolita');
      bolita.style.gridArea = `${posY}/${posX}`;
      bolita.style.opacity = 1;
    }, 500);
  }
  
  // Posiciones permitidas para desplazarse
  const way = [
    '2/2', '2/3', '2/4', '2/5', '2/6', '2/8', '2/9', '2/10', '2/11', '2/12', '2/13', '2/14', '2/16', '2/17', '2/18', '2/19', '2/21', '2/22', '2/23', '2/24', '2/25', '2/26', '2/27', '2/29', 
    '3/2', '3/6', '3/8', '3/14', '3/17', '3/19', '3/21', '3/27', '3/29', 
    '4/2', '4/3', '4/4', '4/6', '4/8', '4/10', '4/11', '4/12', '4/13', '4/14', '4/15', '4/16', '4/17', '4/19', '4/21', '4/22', '4/23', '4/24', '4/25', '4/26', '4/27', '4/29', 
    '5/6', '5/8', '5/16', '5/19', '5/23', '5/27', '5/29', 
    '6/1', '6/2', '6/3', '6/4', '6/5', '6/6', '6/7', '6/8', '6/9', '6/10', '6/12', '6/13', '6/14', '6/15', '6/16', '6/17', '6/19', '6/20', '6/21', '6/22', '6/23', '6/25', '6/26', '6/27', '6/28', '6/29', 
    '7/2', '7/4', '7/8', '7/12', '7/17', '7/21', '7/23', '7/29', 
    '8/2', '8/4', '8/6', '8/7', '8/8', '8/9', '8/10', '8/11', '8/12', '8/13', '8/14', '8/15', '8/17', '8/18', '8/19', '8/20', '8/21', '8/23', '8/24', '8/25', '8/26', '8/27', '8/28', '8/29', 
    '9/2', '9/4', '9/11', '9/15', '9/17', '9/23', '9/29', 
    '10/2', '10/4', '10/5', '10/6', '10/7', '10/8', '10/9', '10/11', '10/13', '10/15', '10/17', '10/18', '10/19', '10/20', '10/21', '10/23', '10/25', '10/26', '10/27', '10/29', 
    '11/2', '11/8', '11/11', '11/13', '11/15', '11/17', '11/19', '11/25', '11/27',
    '12/2', '12/3', '12/4', '12/5', '12/6', '12/8', '12/10', '12/11', '12/12', '12/13', '12/15', '12/17', '12/19', '12/21', '12/22', '12/23', '12/24', '12/25', '12/27', '12/28', '12/29', 
    '13/4', '13/6', '13/8', '13/10', '13/13', '13/15', '13/17', '13/19', '13/21', '13/25', '13/29', 
    '14/2', '14/3', '14/4', '14/6', '14/8', '14/9', '14/10', '14/11', '14/13', '14/15', '14/17', '14/19', '14/21', '14/23', '14/24', '14/25', '14/27', '14/29', 
    '15/2', '15/6', '15/11', '15/13', '15/19', '15/23', '15/27', '15/29', 
    '16/2', '16/4', '16/5', '16/6', '16/8', '16/9', '16/11', '16/12', '16/13', '16/15', '16/16', '16/17', '16/19', '16/20', '16/21', '16/23', '16/25', '16/26', '16/27', '16/28', '16/29', 
    '17/2', '17/4', '17/8', '17/15', '17/17', '17/23', '17/25',
    '18/2', '18/4', '18/5', '18/6', '18/7', '18/8', '18/10', '18/11', '18/12', '18/13', '18/14', '18/15', '18/17', '18/18', '18/19', '18/21', '18/22', '18/23', '18/25', '18/27', '18/28', '18/29',
    '19/2', '19/10', '19/14', '19/17', '19/19', '19/21', '19/27',
    '20/2', '20/3', '20/4', '20/6', '20/7', '20/8', '20/9', '20/10', '20/12', '20/13', '20/14', '20/15', '20/17', '20/21', '20/23', '20/24', '20/25', '20/26', '20/27', '20/28', '20/29', 
    '21/4', '21/8', '21/12', '21/15', '21/17', '21/19', '21/21', '21/23', '21/27', '21/29', 
    '22/2', '22/3', '22/4', '22/5', '22/6', '22/8', '22/9', '22/10', '22/11', '22/12', '22/13', '22/15', '22/17', '22/19', '22/21', '22/23', '22/24', '22/25', '22/27', '22/29', 
    '23/2', '23/6', '23/7', '23/8', '23/13', '23/15', '23/16', '23/17', '23/18', '23/19', '23/21', '23/23', '23/25', '23/29', '23/30', 
    '24/2', '24/4', '24/5', '24/6', '24/8', '24/9', '24/10', '24/11', '24/13', '24/15', '24/19', '24/21', '24/23', '24/25', '24/26', '24/27', '24/29', 
    '25/4', '25/11', '25/13', '25/14', '25/15', '25/17', '25/18', '25/19', '25/21', '25/22', '25/23', '25/27',
    '26/2', '26/3', '26/4', '26/5', '26/6', '26/7', '26/9', '26/11', '26/21', '26/25', '26/29', 
    '27/2', '27/7', '27/9', '27/11', '27/12', '27/13', '27/14', '27/15', '27/16', '27/17', '27/18', '27/19', '27/20', '27/21', '27/23', '27/24', '27/25', '27/26', '27/27', '27/28', '27/29', 
    '28/4', '28/5', '28/6', '28/7', '28/8', '28/9', '28/13', '28/19', '28/23', '28/29', 
    '29/2', '29/3', '29/4', '29/6', '29/7', '29/9', '29/10', '29/11', '29/13', '29/14', '29/15', '29/16', '29/17', '29/19', '29/20', '29/21', '29/22', '29/23', '29/24', '29/25', '29/26', '29/27', '29/28', '29/29',
  ];

  // Mueve la bolita
  const move = (direction) => {
    switch (direction) {
      case 'left':
        posX--
        break;
      case 'right':
        posX++
        break;
      case 'up':
        posY--
        break;
      case 'down':
        posY++
        break;

      default:
        break;
    }

    // Revisa la proxima posicion esta permitida
    const nextPoint = `${posY}/${posX}`;
    const include = way.includes(nextPoint);
    
    // Avanza si la proxima posicion esta permitida
    if (include) {
      let bolita = document.querySelector('.Level2-laberinto-bolita');
      bolita.style.gridArea = nextPoint;
      
      // Revisar si llego a la salida
      if (bolita.style.gridArea === '23 / 30' || bolita.style.gridArea === '23 / 30 / auto / auto') {
        console.log('ganaste');
        
        let planoWrapper = document.querySelector('.Level2-laberinto-plano-wrapper');
        planoWrapper.classList.add('glow');

        setTimeout(() => {
          handleState('laberinto-ok');
        }, 1200);
      }
    } else {
      // Vuelve a la posicion anterior 
      switch (direction) {
        case 'left':
          posX++
          break;
        case 'right':
          posX--
          break;
        case 'up':
          posY++
          break;
        case 'down':
          posY--
          break;
  
        default:
          break;
      }
    }
  }
  
  /**
   * HANDLE SINTOMAS
   * Pinta la caja de sintoma seleccionado
   * Gana si encuentra todas las cajas correctas
   * @param {event} e Evento del click
   */
  
  const handleSintomas = (e) => {
    e.target.classList.add('active');
    e.target.classList.add('zoomIn');
    
    const responsesOks = 4;
    const responsesActived = document.querySelectorAll('.Level2-sintomas-respuesta.active');
    
    if (responsesOks === responsesActived.length) {
      console.log('ganaste');

      setTimeout(() => {
        handleState('sintomas-ok');
      }, 1000);
    }
  }

  /**
   * HANDLE MUSIC
   * Inicia la musica si no esta sonando
   */

   const handleMusic = () => {
    console.log('evaluando musica');
    const music = document.querySelector('.audio-music')

    if (music.paused) {
      console.log('musica esta quieta');
      audio.restart('music');
    } else {
      console.log('musica esta sonando');
    }
  }

  /**
   * SWITCH MUSIC
   * Enciende o apaga la musica
   */

  const switchMusic = (state) => {
    const iconoMusica = document.querySelector('.Home-icono-musica');

    if (iconoMusica) {
      if (state === 'on') {
        audio.volumeOn('music');
        iconoMusica.src = iconoMusicaOn;
        localStorage.setItem('musicAlegra', 'on');
      } else if (state === 'off') {
        audio.volumeOff('music');
        iconoMusica.src = iconoMusicaOff;
        localStorage.setItem('musicAlegra', 'off');
      } else if (state === 'toggle') {
        if (audio.isPlay('music')) {
          if (localStorage.getItem('musicAlegra') === 'on') {
            switchMusic('off');
          } else if (localStorage.getItem('musicAlegra') === 'off') {
            switchMusic('on');
          }
        } else {
          audio.restart('music');
          switchMusic('on');
        }
      }
    }
  }

  setTimeout(() => {
    if (audio.isPlay('music')) {
      if (localStorage.getItem('musicAlegra') === 'on') {
        switchMusic('on');
      } else if (localStorage.getItem('musicAlegra') === 'off') {
        switchMusic('off');
      }
    } else {
      switchMusic('off');
    }    
  }, 500);

  /**
   * SAVE SCORE
   * Guarda el puntaje en el servidor
   */

  const saveScore = () => {
    const score = {
      name: localStorage.getItem('fullname'),
      pharmacy: localStorage.getItem('pharmacy'),
      score: Number(localStorage.getItem('gameSintomasScore')) + Number(localStorage.getItem('gameLaberintoScore')),
      time: Number(localStorage.getItem('gameSintomasTime')) + Number(localStorage.getItem('gameLaberintoTime')),
    }
    
    if (localStorage.getItem('gameSintomas') === 'finish' && localStorage.getItem('gameLaberinto') === 'finish') {
      console.log('Save score!');

      const rankingAllegraLevel2 = localStorage.getItem('rankingAllegraLevel2');

      if (!rankingAllegraLevel2) {
        console.log('no existe ranking');
        localStorage.setItem('rankingAllegraLevel2', JSON.stringify([score]));
      } else {
        console.log('ya existe ranking');
        const ranking = JSON.parse(localStorage.getItem('rankingAllegraLevel2'));
        ranking.push(score);

        localStorage.setItem('rankingAllegraLevel2', JSON.stringify(ranking));
      }
      
      localStorage.setItem('gameSintomas', 'saved');
      localStorage.setItem('gameLaberinto', 'saved');
    }
  }

  /**
   * USE EFFECT
   * Acciones iniciales
   */

  // React.useEffect(() => {
  //   handleState('laberinto');
  // }, []);

  return (
    <div className='Level2'>
      <State />
      <div className='Level2-background fadeIn' />
      <img className='Level2-logo-allegra' src={logoAllegra} alt="Logo Alegra" />
      <img className='Level2-nivel' src={nivel} alt="Nivel" />
      <button className='button right bottom' onClick={() => { 
        handleNavigate('welcome'); 
        audio.play('modalOut') 
      }} >MENU</button>
      <img className='Home-icono-musica absolute left bottom' src={iconoMusicaOn} alt="Musica" onClick={() => switchMusic('toggle')} />

      {/* Presentacion */}

      {!state.screen &&
        <React.Fragment>
          { localStorage.setItem('gameSintomas', 'ready') }
          { localStorage.setItem('gameSintomasScore', 0) }
          { localStorage.setItem('gameSintomasTime', 0) }
          { localStorage.setItem('gameLaberinto', 'ready') }
          { localStorage.setItem('gameLaberintoScore', 0) }
          { localStorage.setItem('gameLaberintoTime', 0) }

          <img className='Level2-hombre-feliz slideUp' src={hombreFeliz} alt="Hombre" />
          <img className='Level2-producto zoomIn' src={producto} alt="Producto" />
          <img className='Level2-titulo' src={titulo} alt="Titulo" />
          <img className='Home-jugar-btn' src={jugar} alt="Jugar" onClick={() => { 
            handleState('instrucciones');
            handleMusic();
            audio.play('button');
          }} />
        </React.Fragment>
      }

      {/* Instrucciones */}

      {state.screen === 'instrucciones' &&
        <React.Fragment>
          <img className='Level2-hombre-enfermo' src={hombreEnfermo} alt="Hombre" />
          <img className='Level2-mensaje zoomIn' src={mensaje} alt="Titulo" onClick={() => { 
            handleState('sintomas');
            audio.play('button');
          }} />
        </React.Fragment>
      }

      {/* Sintomas */}

      {state.screen === 'sintomas' &&
        <Overlay>
          <div className="Level2-sintomas-wrapper">
            <img className='Level2-sintomas-pregunta' src={sintomasPregunta} alt="Pregunta" />
            <div className="Level2-sintomas-respuestas">
              <img className='Level2-sintomas-respuesta' src={sintomasRespuesta1} alt="Sintomas respuesta" onClick={(e) => { 
                handleSintomas(e);
                audio.play('button');
              }} />
              <img className='Level2-sintomas-respuesta' src={sintomasRespuesta2} alt="Sintomas respuesta" onClick={() => { 
                handleState('sintomas-error');
                audio.play('button');
              }} />
              <img className='Level2-sintomas-respuesta' src={sintomasRespuesta3} alt="Sintomas respuesta" onClick={(e) => { 
                handleSintomas(e);
                audio.play('button');
              }} />
              <img className='Level2-sintomas-respuesta' src={sintomasRespuesta4} alt="Sintomas respuesta" onClick={() => { 
                handleState('sintomas-error');
                audio.play('button');
              }} />
              <img className='Level2-sintomas-respuesta' src={sintomasRespuesta5} alt="Sintomas respuesta" onClick={(e) => { 
                handleSintomas(e);
                audio.play('button');
              }} />
              <img className='Level2-sintomas-respuesta' src={sintomasRespuesta6} alt="Sintomas respuesta" onClick={(e) => { 
                handleSintomas(e);
                audio.play('button');
              }} />
            </div>
          </div>
          <button className='button right bottom z-200' onClick={() => { 
            handleState(''); 
            audio.play('modalOut') 
          }} >SALIR</button>
          
          <img className='Level2-sintomas-segundos' src={sintomasSegundos} alt="Segundos Reto" />
          <Reloj timeOut="120" timeOutScreen={'sintomas-timeout'} game="Sintomas" />
        </Overlay>
      }

      {/* Sintomas - Callbacks */}

      {state.screen === 'sintomas-timeout' &&
        <Overlay>
          <img className='Level2-sintomas-hombre-enfermo slideRight' src={hombreEnfermo} alt="Hombre" />
          <img className='Level2-sintomas-ups-tiempo' src={sintomasUpsTiempo} alt="Timeout" onClick={() => {
            handleState('sintomas');
            audio.play('modalOut');
          }} />
        </Overlay>
      }

      {state.screen === 'sintomas-error' &&
        <Overlay>
          <img className='Level2-sintomas-hombre-enfermo slideRight' src={hombreEnfermo} alt="Hombre" />
          <img className='Level2-sintomas-ups-fallaste' src={sintomasUpsFallaste} alt="Incorrecto" onClick={() => {
            handleState('sintomas');
            audio.play('button');
          }} />
        </Overlay>
      }

      {state.screen === 'sintomas-ok' &&
        <Overlay>
          { localStorage.getItem('gameSintomas') === 'ready' && localStorage.setItem('gameSintomas', 'finish') }
          { localStorage.setItem('gameSintomasScore', 4) }

          <img className='Level2-sintomas-hombre-feliz slideUp' src={hombreFeliz} alt="Hombre" />
          <img className='Level2-sintomas-excelente zoomIn' src={sintomasExcelente} alt="Bien" onClick={() => {
            handleState('laberinto');
            audio.play('button');
          }} />
        </Overlay>
      }

      {/* Laberinto */}

      {state.screen === 'laberinto' &&
        <Overlay>
          <div className="Level2-laberinto-wrapper">
            <img className='Level2-laberinto-pregunta' src={laberintoPregunta} alt="Pregunta" />
            <div className="Level2-laberinto-juego">
              <img className='Level2-laberinto-producto' src={producto} alt="Producto" />
              <div className="Level2-laberinto-plano-wrapper">
                <img className='Level2-laberinto-plano' src={laberintoPlano} alt="Habitacion" />
                <div className='Level2-laberinto-grid'>
                  <div className='Level2-laberinto-bolita' />
                </div>
              </div>
              <img className='Level2-laberinto-hombre' src={hombreFeliz} alt="Hombre" />
            </div>
          </div>
          <div className="Level2-laberinto-joystick">
            <button className='Level2-laberinto-left-btn' type='button' onClick={() => move('left')}>←</button>
            <button className='Level2-laberinto-right-btn' type='button' onClick={() => move('right')}>→</button>
            <button className='Level2-laberinto-up-btn' type='button' onClick={() => move('up')}>↑</button>
            <button className='Level2-laberinto-down-btn' type='button' onClick={() => move('down')}>↓</button>
            <div className="Level2-laberinto-centro" />
            <div className="Level2-laberinto-cursor" />
          </div>
          <button className='button left bottom z-200' onClick={() => { 
            handleState(''); 
            audio.play('modalOut') 
          }} >SALIR</button>
          
          <img className='Level2-laberinto-segundos' src={laberintoSegundos} alt="Segundos Reto" />
          <Reloj timeOut="120" timeOutScreen={'laberinto-timeout'} game="Laberinto" />
        </Overlay>
      }

      {/* Laberinto - Callbacks */}

      {state.screen === 'laberinto-timeout' &&
        <Overlay>
          <img className='Level2-laberinto-hombre-enfermo slideRight' src={hombreEnfermo} alt="Hombre" />
          <img className='Level2-laberinto-ups' src={laberintoUpsTiempo} alt="Timeout" onClick={() => {
            handleState('laberinto');
            audio.play('modalOut');
          }} />
        </Overlay>
      }

      {state.screen === 'laberinto-ok' &&
        <Overlay>
          { localStorage.getItem('gameLaberinto') === 'ready' && localStorage.setItem('gameLaberinto', 'finish') }
          { localStorage.setItem('gameLaberintoScore', 1) }
          
          { saveScore() }

          <img className='Level2-laberinto-hombre-feliz slideUp' src={hombreFeliz} alt="Hombre" />
          <div className="Level2-laberinto-bien-wrapper zoomIn">
            <img className='Level2-laberinto-bien' src={laberintoBien} alt="Bien" />
            <div className='Level2-laberinto-continuar' onClick={() => {
              handleNavigate('level3');
              audio.play('button');
            }} />
            <div className='Level2-laberinto-menu' onClick={() => {
              handleNavigate('welcome');
              audio.play('button');
            }} />
            <div className='Level2-laberinto-referencias' onClick={() => {
              handleState('referencias');
              audio.play('button');
            }} />
          </div>
          <button className='button right bottom z-200' onClick={() => { 
            handleNavigate('finish');
            audio.play('modalOut');
          }} >CALIFÍCANOS</button>
        </Overlay>
      }

      {/* Referencias */}

      {state.screen === 'referencias' &&
        <Overlay>
          <div>
            <img className='Level2-referencias-close' src={cerrar} alt="Cerrar" onClick={() => {
              handleState('laberinto-ok');
              audio.play('modalOut');
            }} />
            <img className='Level2-referencias' src={referencias} alt='Referencias' />
          </div>
        </Overlay>
      }
    </div>
  );
}

export default Level2;