import React from 'react';

// screen: Indica la pantalla actual del nivel

const initialState = {
  screen: '',
}

const useInitialState = () => {
  const [ state, setState ] = React.useState(initialState);

  return {
    state,
    setState,
  };
}

export default useInitialState;