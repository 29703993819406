import React from 'react'

import logo from '../assets/images/common/logo-allegra.png';

const NotFound = () => {
  return (
    <div className='NotFound'>
      <div className='NotFound-background' />
      <img className='NotFound-logo' src={logo} alt="Logo" />
      <div className='NotFound-texto'>Página no encontrada</div>
    </div>
  );
}

export default NotFound;