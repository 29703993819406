import React from 'react'

const useAudio = () => {
  
  const play = (resource) => {
    const audio = document.querySelector('.audio-'+resource);
    audio.play();
  }

  const stop = (resource) => {
    const audio = document.querySelector('.audio-'+resource);
    audio.pause();
    audio.currentTime = 0;
  }

  const pause = (resource) => {
    const audio = document.querySelector('.audio-'+resource);
    audio.pause();
  }

  const fadeOut = (resource) => {
    const audio = document.querySelector('.audio-'+resource);

    const fader = setInterval(() => {      
      if (audio.volume.toFixed(1) > 0.1) {
        audio.volume -= 0.1;
      } else {
        audio.volume = 0
        audio.pause();
        clearInterval(fader);
      }
    }, 100);
  }

  const fadeIn = (resource) => {
    const audio = document.querySelector('.audio-'+resource);

    const fader = setInterval(() => {      
      audio.volume = 0;
      audio.play();

      if (audio.volume.toFixed(1) < 0.9) {
        audio.volume += 0.1;
      } else {
        audio.volume = 1
        clearInterval(fader);
      }
    }, 100);
  }

  const restart = (resource) => {
    const audio = document.querySelector('.audio-'+resource);
    audio.currentTime = 0;
    audio.play();
  }

  const volumeOff = (resource) => {
    const audio = document.querySelector('.audio-'+resource);
    audio.volume = 0;
  }

  const volumeOn = (resource) => {
    const audio = document.querySelector('.audio-'+resource);
    audio.volume = 1;
  }

  const isPlay = (resource) => {
    const audio = document.querySelector('.audio-'+resource);
    
    if (audio.currentTime === 0) {
      return false;
    } else {
      return true;
    }
  }

  return {
    play,
    stop,
    pause,
    fadeOut,
    fadeIn,
    restart,
    volumeOff,
    volumeOn,
    isPlay,
  };
  
}

export default useAudio;